import { Button, CircularProgress, useMediaQuery, useTheme } from "@mui/material";
import { makeStyles } from '@mui/styles';
import React, {useContext, useMemo, useRef, useState} from "react";
import {jigpawConverter, Photobook} from "../../models";
import _ from "lodash";
import ConfirmationDialog from "../../global/components/ConfirmationDialog";
import {JigpawSinglePicker} from "../../global/components/JigpawPicker";
import {AutoScroller, getResponsiveStyle, ItemDrag, MuuriComponent, useDraggable, useGrid} from "muuri-react";
import {PhotobookStyleContext} from "../../utils";
import {useMainImage} from "../../storageUtils";
import {ImageEditorModal} from "../../products/components/ImageEditorModal";
import {doc, getDoc} from "firebase/firestore";
import {db} from "../../firebase";
import BlankPage from "./BlankPage";
import Label from "./Label";
import Margin from "./Margin";
import HiddenPage from "./HiddenPage";
import Modal from "./Modal";

const SmallImageWidth = 0.5;
const MediumFullScreenWidth = 1000;
const MediumImageWidth = 227.5 / MediumFullScreenWidth;
const MediumMargin = 30 / MediumFullScreenWidth;
const MediumMarginRatio = 30 / 227.5;
// const MediumLabelRatio = (227.5 / 30) / 2;

const LargeFullScreenWidth = 1500;
const LargeImageWidth = 228 / LargeFullScreenWidth;
const LargeMargin = 33 / LargeFullScreenWidth;
const LargeMarginRatio = 33 / 228;


const useStyles = makeStyles((theme) => ({
    labelContainer: {
        height: "40px",
        textAlign: "left",
        display: "flex",
         flexDirection: 'column',
        justifyContent: 'flex-end',
        fontSize: '20px',
        fontWeight: '400',
    },
    buttonContainer: {
        fontSize: '14px',
        fontWeight: '400',
    },
    mobileFix: {
        touchAction: "pan-y !important"
    }
}));


const PageImage = ({user, page, pageIndex}) => {

    const [loading, setLoading] = useState(false);
    const [retryDelay, setRetryDelay] = useState(500);
    const [retryCount, setRetryCount] = useState(0);
    const maxRetries = 25;

    const {url} = useMainImage(user, _.get(page, "oid"), _.get(page, "jigpawId"));

    if (!url) {
        return (<div><CircularProgress/></div>)
    }
    console.log("PageImageUrl:", url);

    const onError = (error) => {
        console.log("onError:", error, "retryCount:", retryCount, "retryDelay:", retryDelay);
        setLoading(true)
        if (retryCount < maxRetries) {
            setRetryDelay(retryDelay * 2)
            setRetryCount(retryCount + 1)
            setTimeout(() => {
                setLoading(false);
            }, retryDelay);
        }
    }

    if (loading) {
        return (<div><CircularProgress/></div>)
    }

    return (
        <img
            src={url}
            width="100%"
            height="100%"
            alt={`Photobook image page ${pageIndex}`}
            onError={onError}
        />
    )
}

const isPageItem = (item) => {
    return item.getKey().startsWith("page");
};

const isToolbarItem = (item) => {
    return item.getKey().startsWith("toolbar");
};






// Item component.
const Page = ({pageKey, user, page, initialPageIndex, setPickerOpen, setPickerIndex}) => {
    // The style concerns only the "dimensions" and "margins" of the items.
    const {style} = useContext(PhotobookStyleContext);
    const setDraggable = useDraggable();
    const {grid} = useGrid();

    setDraggable(Boolean(page));

    const index = () => {
        const items = grid.getItems();
        // console.log("items:", items);
        const pageItems = _.filter(items, isPageItem);
        // console.log("pageItems", pageItems)
        return _.findIndex(pageItems, (item) => {
            return item.getKey() === pageKey
        })
    }


    const openPicker = () => {
        const pageIndex = index()
        if (pageIndex !== -1) {
            setPickerIndex(pageIndex+1)
            setPickerOpen(true);
        }
    }

    const calculatedIndex = index()
    const pageIndex = (calculatedIndex === -1) ? initialPageIndex : calculatedIndex + 1;
    const className = (pageIndex % 2 === 0) ? "item-content odd" : "item-content even";
    // console.log("pageIndex:", pageIndex, "className:", className);

    return (

        <div style={style} className={`item`}>
            { page && <div className={className}> <PageImage user={user} page={page} pageIndex={pageIndex}></PageImage></div>}
            { !page && <div className={className} onClick={openPicker}> <img src='/images/icons/uploadIcon.svg'
                                                         style={{"width": "100px"}}/></div>}
        </div>
    );
};

// 

const Toolbar = ({toolbarKey, page, setDeleteDialogOpen, setDeletePageIndex, setPickerOpen, setPickerIndex, setEditorDialogOpen, setEditorIndex, setEditorJigpaw, openModal}) => {
    const classes = useStyles();
    const {labelStyle} = useContext(PhotobookStyleContext);
    const {grid} = useGrid();
    const setDraggable = useDraggable();
    setDraggable(false);




    const index = () => {
        const items = grid.getItems();
        // console.log("toolbar grid Items:", items)
        const pageItems = _.filter(items, isToolbarItem);
        return _.findIndex(pageItems, (item) => {
            return item.getKey() === toolbarKey
        })
    }

    const openRemoveDialog = () => {
        const deleteIndex = index()
        if (deleteIndex !== -1) {
            setDeletePageIndex(deleteIndex)
            setDeleteDialogOpen(true)
        }
    }

    const openPicker = () => {
        const pageIndex = index()
        if (pageIndex !== -1) {
            setPickerIndex(pageIndex)
            setPickerOpen(true)
        }
    }


    const openEditor = async () => {
        try {
            const pageIndex = index();
            const jigpawId = _.get(page, "jigpawId");
            if (pageIndex !== -1 && !_.isNil(jigpawId)) {
                const docRef = doc(db, "jigpaws", jigpawId).withConverter(jigpawConverter);
                const docSnap = await getDoc(docRef);
        
                if (docSnap.exists()) {
                    setEditorIndex(pageIndex);
                    setEditorJigpaw(docSnap.data());
                    setEditorDialogOpen(true);
                } else {
                    throw new Error("Stock images cannot be edited");  // Specific error if the document doesn't exist
                }
            } else {
                throw new Error("Invalid page index or ID");
            }
        } catch (error) {
            console.error("Failed to open editor:", error);
            openModal("Stock images cannot be edited"); // Use openModal to display the error
        }
    };

    return (
        <div style={labelStyle} className={classes.mobileFix}>
            <div style={{
                position: 'absolute',
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
            }}>
                {page &&
                    <>

                                        <div className="buttonContainer">

                        <Button variant="text" size="small"  onClick={openEditor}><img src={"/images/icons/editImageSmall.svg"} width={"25px%"}/></Button>
                        <Button variant="text" size="small" onClick={openPicker}><img src={"/images/icons/swapImageSmall.svg"} width={"25px%"} /></Button>
                        <Button variant="text" size="small" onClick={openRemoveDialog}><img src={"/images/icons/delImageSmall.svg"} width={"25px"}/></Button>
                                        </div>
                    </>
                }
            </div>
        </div>
    )
}




const gridUpdated = (user, photobook, grid, previousItems) => {
    const items = grid.getItems();
    const pageItems = _.filter(items, isPageItem);
    const previousPageItems = _.filter(previousItems, isPageItem);

    if (_.isEqual(pageItems, previousPageItems)) {
        // console.log("No change");
    } else {
        // console.log("save required");
        const pages = _.map(pageItems, (pageItem) => {
            return pageItem.getProps().page;
        });
        photobook.updatePages(pages);
    }
};

const imageColumns = (isDesktopScreen, isExtraLargeDesktop) => {
    if (isExtraLargeDesktop) {
        return LargeImageWidth
    }
    if (isDesktopScreen) {
        return MediumImageWidth
    }
    return SmallImageWidth;
}

const marginColumns = (isDesktopScreen, isExtraLargeDesktop) => {
    if (isExtraLargeDesktop) {
        return LargeMargin
    }
    if (isDesktopScreen) {
        return MediumMargin
    }
    return 0.1; //small screen doesn't currently have margin
}

const marginRatio = (isDesktopScreen, isExtraLargeDesktop) => {
    // This is for the margin on the image row.
    if (isExtraLargeDesktop) {
        return LargeMarginRatio
    }
    if (isDesktopScreen) {
        return MediumMarginRatio
    }
    return 0; //small screen doesn't currently have margin
}

// Responsive theme provider.
const PhotobookStyleProvider = ({children}) => {
    const theme = useTheme();
    const isDesktopScreen = useMediaQuery(theme.breakpoints.up('md'), { noSsr: true });
    const isExtraLargeDesktop = useMediaQuery(theme.breakpoints.up('xl'), { noSsr: true });

    // Memoize the style.
    const style = useMemo(() => {
        return getResponsiveStyle({
            columns: imageColumns(isDesktopScreen, isExtraLargeDesktop),
            margin: "0",
            ratio: 1
        });
    }, [isDesktopScreen, isExtraLargeDesktop]);

    const labelStyle = useMemo(() => {
        return getResponsiveStyle({
            columns: imageColumns(isDesktopScreen, isExtraLargeDesktop),
            margin: "0",
            height: "50px",
        });
    }, [isDesktopScreen, isExtraLargeDesktop]);

    return (
        <PhotobookStyleContext.Provider value={{style, labelStyle, toolbarStyle: labelStyle}}>{children}</PhotobookStyleContext.Provider>
    );
};


const columnTypes = (isDesktopScreen, isExtraLargeDesktop) => {
    if (isExtraLargeDesktop) {
        return  ['margin', 'image', 'image', 'margin', 'image', 'image', 'margin', 'image', 'image', 'margin'];
    }
    if (isDesktopScreen) {
        return  ['margin', 'image', 'image', 'margin', 'image', 'image', 'margin'];
    }
    return ['image', 'image'];
}

const imageColumnsPerRow = (isDesktopScreen, isExtraLargeDesktop) => {
    return _.countBy(columnTypes(isDesktopScreen, isExtraLargeDesktop))['image']
}


const PhotobookPages = ({user, photobook}) => {
    // Items state.
    const theme = useTheme();
    const isDesktopScreen = useMediaQuery(theme.breakpoints.up('md'), { noSsr: true });
    const isExtraLargeDesktop = useMediaQuery(theme.breakpoints.up('xl'), { noSsr: true });

    const [pickerOpen, setPickerOpen] = useState(false);
    const [pickerIndex, setPickerIndex] = useState();
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [deletePageIndex, setDeletePageIndex] = useState();
    const [previousItems, setPreviousItems] = useState();

    const [editorDialogOpen, setEditorDialogOpen] = useState(false);
    const [editorIndex, setEditorIndex] = useState();
    const [editorJigpaw, setEditorJigpaw] = useState();

    const [modalOpen, setModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');

    const openModal = (message) => {
        setModalMessage(message);
        setModalOpen(true);
    };
    
    const closeModal = () => {
        setModalOpen(false);
    };

    // console.log("isDesktopScreen", isDesktopScreen);

    const handleCloseJigpawPicker = (jigpaw) => {
        setPickerOpen(false);
        if (jigpaw) {
            photobook.updatePage(pickerIndex, jigpaw)
            // console.log("handleCloseJigpawPicker", jigpaw, pickerIndex);
        }
    };

    const deleteConfirm = () => {
        // console.log("deleteConfirm")
        setDeleteDialogOpen(false);
        photobook.deletePage(deletePageIndex);
    };

    const deleteCancel = () => {
        // console.log("deleteCancel")
        setDeleteDialogOpen(false);
    };

    const jigpawCreated = (jigpaw) => {
        // console.log("jigpawCreated", jigpaw)
        if (jigpaw) {
            photobook.updatePage(editorIndex, jigpaw)
        }
    }

    const editorClosed = () => {
        // console.log("editorClosed")
        setEditorDialogOpen(false);
    }

    const pageKeysIncludingBlanks = Photobook.pageKeysIncludingBlanks();

    const children = useMemo(() => {

        let newChildren = [];
        let pageIndex = 0;
        let labelIndex = 0;
        let toolbarIndex = 0;

        let marginIndex = 0;

        let columns = columnTypes(isDesktopScreen, isExtraLargeDesktop);
        let rows = ["label", "page", "toolbar"];


        const marginStyle = getResponsiveStyle({
            columns: marginColumns(isDesktopScreen, isExtraLargeDesktop),
            margin: "0",
            ratio: marginRatio(isDesktopScreen, isExtraLargeDesktop)
        });

        const labelMarginStyle = getResponsiveStyle({
            columns: marginColumns(isDesktopScreen, isExtraLargeDesktop),
            margin: "0",
            height: "50px",
        });

        const step = imageColumnsPerRow(isDesktopScreen, isExtraLargeDesktop)
        _.forEach(_.range(0, pageKeysIncludingBlanks.length, step), () => {
            _.forEach(rows, (rowType) => {
                _.forEach( columns, (columnType, index) => {
                    if (rowType === "label" && columnType === "margin") {
                        newChildren.push(
                            (<Margin key={`margin${marginIndex}`} style={labelMarginStyle} index={index} background={"green"}></Margin>)
                        )
                        marginIndex += 1;
                    } else if (rowType === "label" && columnType === "image") {
                        let title = `Page ${labelIndex}`
                        if (Photobook.isFrontBlank(labelIndex)) {
                            title = 'Inside cover'
                        }
                        if (Photobook.isBackBlank(labelIndex)) {
                            title = 'Inside back cover'
                        }
                        if (labelIndex > 21) {
                            title = '' //fixes grid alignment
                        }
                        newChildren.push(
                            (<Label key={`label${labelIndex}`} title={title}></Label>)
                        )
                        // console.log("labelIndex:", labelIndex)
                        labelIndex += 1;
                    } else if (rowType === "page" && columnType === "margin") {
                        newChildren.push(
                            (<Margin key={`margin${marginIndex}`} style={marginStyle} index={index} background={"yellow"}></Margin>)
                        )
                        marginIndex += 1;
                    } else if (rowType === "page" && columnType === "image" && Photobook.isFrontBlank(pageIndex)) {
                        newChildren.push(
                            <BlankPage key={`blankpage${pageIndex}`} isFrontPage={true} isBackPage={false}></BlankPage>
                        )
                        pageIndex += 1;
                    }
                    else if (rowType === "page" && columnType === "image" && Photobook.isBackBlank(pageIndex)) {
                        newChildren.push(
                            <BlankPage key={`blankpage${pageIndex}`} isFrontPage={false} isBackPage={true}></BlankPage>
                        )
                        pageIndex += 1;
                    }
                    else if (rowType === "page" && columnType === "image" && Photobook.isPage(pageIndex)) {
                        const page = photobook.getPage(pageIndex)
                        // console.log(pageIndex, page);
                        newChildren.push(
                            (<Page key={`page${pageIndex}`} pageKey={`page${pageIndex}`}
                                   initialPageIndex={pageIndex} user={user} page={page}
                                   setPickerOpen={setPickerOpen} setPickerIndex={setPickerIndex}></Page>)
                        )
                        pageIndex += 1;
                    }
                    else if (rowType === "page" && columnType === "image" && pageIndex > 21) {
                        newChildren.push(
                                <HiddenPage key={`hidden_page${pageIndex}`}></HiddenPage>
                            )
                        pageIndex += 1;
                    } else if (rowType === "toolbar" && columnType === "margin") {
                        newChildren.push(
                            (<Margin key={`margin${marginIndex}`} style={labelMarginStyle} index={index} background={"red"}></Margin>)
                        )
                        marginIndex += 1;
                    } else if (rowType === "toolbar" && columnType === "image") {
                        const page = photobook.getPage(toolbarIndex)
                        newChildren.push(
                            (<Toolbar 
                                key={`toolbar${toolbarIndex}`} 
                                toolbarKey={`toolbar${toolbarIndex}`} 
                                page={page}
                                setPickerOpen={setPickerOpen} 
                                setPickerIndex={setPickerIndex}
                                setDeleteDialogOpen={setDeleteDialogOpen}
                                setDeletePageIndex={setDeletePageIndex}
                                setEditorDialogOpen={setEditorDialogOpen}
                                setEditorIndex={setEditorIndex}
                                setEditorJigpaw={setEditorJigpaw}
                                openModal={openModal}
                            ></Toolbar>)
                        )
                        toolbarIndex += 1;
                    }
                })
            })
        })

        // console.log("newChildren", newChildren);

        return newChildren
    }, [isDesktopScreen, isExtraLargeDesktop, photobook]);

    // const children = newChildren

    const sort = _.map(children, "key");


    // Items to children.
    // const children = items.map((props) => <Item key={props.id} {...props} />);
    const scrollElemRef = useRef();

    // const isComplete = photobook.isComplete();


    const PhotobookPageProduct = {
        category: "photobookPage",
        id: "photobookPage",
    }


    return (
        <>
            <ConfirmationDialog title={"Remove Image"} open={deleteDialogOpen} handleCancel={deleteCancel} handleConfirm={deleteConfirm} cancelText={"Cancel"} confirmText={"Yes"} />
            <JigpawSinglePicker open={pickerOpen} onClose={handleCloseJigpawPicker} user={user}/>
            <ImageEditorModal open={editorDialogOpen} user={user} jigpaw={editorJigpaw} product={PhotobookPageProduct} jigpawCreated={jigpawCreated} onClose={editorClosed} />
            <PhotobookStyleProvider>
                <div className="sc" ref={scrollElemRef}>
                    <MuuriComponent
                        sort={sort}
                        dragEnabled
                        dragFixed
                        forceSync
                        instantLayout={true}
                        // dragStartPredicate={{
                        //     distance: 10,
                        //     delay: 100,
                        // }}
                        dragSortPredicate={function (item, e) {
                            //this prevents you dragging over/ replacing labels
                            var result = ItemDrag.defaultSortPredicate(item, {
                                action: "swap",
                                // migrateAction: "swap", between grids
                                threshold: 50
                            })
                            console.log(result);
                            if (!result) {
                                return false;
                            }
                            const grid = result.grid;
                            const currentItem = grid.getItem(result.index);

                            if (!currentItem.getKey().startsWith("page")) {
                                return false
                            }
                            return result;
                        }}
                        dragSortHeuristics={{
                            sortInterval: 0
                        }}
                        // dragPlaceholder={{
                        //   enabled: true,
                        //   createElement: function (item) {
                        //     return item.getElement().cloneNode(true);
                        //   },
                        // }}
                        // dragContainer={scrollElemRef}
                        dragAutoScroll={{
                            // TODO: Look into mobile....
                            targets: [
                                {
                                    element: window,
                                    axis: AutoScroller.AXIS_Y
                                },
                            ],
                        }}
                        onDragStart={(item, event) => {
                            const items = item.getGrid().getItems();
                            setPreviousItems(_.clone(items))
                        }}
                        onDragEnd={function (item) {
                            const grid = item.getGrid();
                            gridUpdated(user, photobook, grid, previousItems);
                        }}
                    >
                        {children}
                    </MuuriComponent>
                </div>
            </PhotobookStyleProvider>
            <Modal
                open={modalOpen}
                onClose={closeModal}
                title="Error"
                message={modalMessage}
            />
        </>
    );
};

export default PhotobookPages;



