/* React */
/*jshint esversion: 6 */
import React from "react";
import '@uppy/core/dist/style.css';
import '@uppy/drag-drop/dist/style.css';
import {app} from "../../firebase";
import {getAuth} from "firebase/auth";
import {useAuthState} from 'react-firebase-hooks/auth';
import {useDocumentData} from 'react-firebase-hooks/firestore';
import {doc, getFirestore} from "firebase/firestore";
import {makeStyles} from '@mui/styles';
import {useProducts} from "../../API";
import _ from "lodash";
import {useParams} from "react-router-dom";
import {Container} from "@mui/material";
import {jigpawConverter} from "../../models";
import {PageProgress} from "../../global/components/ProgressBars";
import BalanceText from "react-balance-text";
import {useLocale} from "../../api-locale";
import ProductsGrid from "../../products/components/ProductsGrid";

const useStyles = makeStyles((theme) => ({
    containerSpacing: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    }
}));


const Products = ({jigpawId, user, currency}) => {
    const db = getFirestore(app)
    const [productResponse, error] = useProducts("all", currency);
    const ref = (jigpawId) ? doc(db, "jigpaws", jigpawId).withConverter(jigpawConverter) : null
    //todo
    // const [jigpaw, jigpawLoading, jigpawError] = useDocumentData(
    const [jigpaw] = useDocumentData(
        ref
    );
    const classes = useStyles();


    console.log(productResponse)

    if (productResponse && productResponse.products && jigpaw) {
        let productList = productResponse.products
        productList = _.uniqBy(productList, "category");
        productList = _.sortBy(productList, "display_order");

        return (
            <>
                <Container className={"productsHome bgGray"} align="center" disableGutters={true}
                           maxWidth={false}>
                    <div className="spacer"/>
                    <div className="headerContainer">

                        <div><h1><BalanceText>CREATE A PERSONALISED GIFT</BalanceText></h1></div>
                        <div className="body"><BalanceText>Select a lovingly handcrafted personalised gift below and
                            create the perfect JIGPAWS present to send to friends and family.</BalanceText>
                            <BalanceText>All items ship globally – free UK shipping.</BalanceText>

                        </div>
                    </div>
                    <div className="spacer"></div>
                    <div className="jpDivider bgBlue"/>
                </Container>

                <Container maxWidth={"xl"} className={classes.containerSpacing}>

                    <ProductsGrid products={productList} jigpaw={jigpaw} user={user} currency={currency}></ProductsGrid>
                </Container>
            </>
        )
    } else {
        return (
            null
        )
    }
}

const ProductsCurrency = ({user, jigpawId}) => {
    //todo
    // const {locale, loading, e} = useLocale(user);
    const {locale } = useLocale(user);
    if (locale) {
        return (
            <Products user={user} jigpawId={jigpawId} currency={locale.currencyCode}></Products>
        );
    }
    return (
        <PageProgress></PageProgress>
    );

}


const ProductPage = () => {
    // Items state.
    const auth = getAuth(app);
    //todo
    // const [user, authLoading, authError] = useAuthState(auth);
    const [user, authError] = useAuthState(auth);
    const {jigpawId} = useParams();

    if (authError) {
        return (
            <div>
                <p>Error: {authError}</p>
            </div>
        );
    }
    if (user) {
        return (
            <ProductsCurrency user={user} jigpawId={jigpawId}></ProductsCurrency>
        );
    }
    return (
        <PageProgress></PageProgress>
    );
};

export default ProductPage
