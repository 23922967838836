import React, { useState } from 'react';
import {Container} from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Link from "@mui/material/Link";
import {getAuth, signOut} from "firebase/auth";
import { app, signIn } from "./firebase";
import {useAuthState} from "react-firebase-hooks/auth";
import {Link as RouterLink, useLocation, useHistory} from "react-router-dom";
import {useFormStyles, useQuery} from "./utils";
import _ from "lodash";


let signingOut = false;


const SignIn = () => {
    const formClasses = useFormStyles();
    const auth = getAuth(app);
    const [user, loading, error] = useAuthState(auth);

    const query = useQuery();
    const initialEmail = query.get("email");
    const [email, setEmail] = useState(initialEmail);
    const [password, setPassword] = useState('');

    const location = useLocation();
    const history = useHistory();
    const signInRedirect = _.get(location, "state.redirect", "/create");

    const emailOnChange = e => {
        e.preventDefault();
        setErrors(_.omit(error, "email"));
        setEmail(e.target.value);
    };

    const passwordOnChange = e => {
        e.preventDefault();
        setErrors(_.omit(error, "password"))
        setPassword(e.target.value);
    };

    const [handlingSubmit, setHandlingSubmit] = useState(false);
    const [errors, setErrors] = useState({});
    const [errorCode, setErrorCode] = useState();
    const [errorMessage, setErrorMessage] = useState();

    const handleSubmit = e => {
        setHandlingSubmit(true);
        setErrors({});
        setErrorMessage(null);
        setErrorCode(null);
        e.preventDefault();

        if (user) {
            //START spinner
            signIn(user, email, password)
                .then(() => {
                    // console.log("signed in...");
                    //end spinner
                    history.push(signInRedirect);
                })
                .catch((error) => {
                    //end spinner
                    // console.log("sign in failed...", error);
                    // https://firebase.google.com/docs/auth/admin/errors

                    setErrorCode(error.code);

                    switch (error.code) {
                        case 'auth/user-not-found':
                            setErrors({'email': 'Account not found...'})
                            break;
                        case 'auth/wrong-password':
                            setErrors({'password': 'Wrong password...'})
                            break;
                        default:
                            setErrorMessage(error.message);
                    }
                    setHandlingSubmit(false);
                });
        }
    };

    const handleSignOut = e => {
        e.preventDefault();
        if (!signingOut) {
            signingOut = true;
            signOut(auth).then(() => {
                // console.log("signed out... from sign in page");
            }).catch((error) => {
                // console.log("failed sign out... from sign in page");
            }).finally(() => {
                // console.log("signing out finally");
                signingOut = false;
            });
        }
    };

    if (loading) {
        return (null);
    }
    if (error) {
        return (null);
    }
    if (user && !user.isAnonymous) {
        return <div>Logged in as {user.displayName} ({user.email}) <Link onClick={handleSignOut} underline="hover">Sign out</Link></div>;
    }

    const forgotUrl = (email && email.length > 3) ? `/forgotten-password?email=${email}` : `/forgotten-password`;
    const createUrl = (email && email.length > 3) ? `/create-account?email=${email}` : `/create-account`;

    return <>
                <Container className={"productsHome bgGray"} disableGutters={true} maxWidth={false}>
                    <div className="spacer"/>
                    <div><h1>Sign in</h1></div>
                    <div className="spacer"></div>
                    <div className="jpDivider bgBlue"/>
                </Container>
        <Container disableGutters={true} className={formClasses.container}>
                                <div className="spacer"/>

        <div className="formBox">
        <form className={formClasses.root} onSubmit={handleSubmit}>
            <TextField
                label="Email"
                variant="filled"
                type="email"
                value={email}
                onChange={emailOnChange}
                required
                error={errors.email}
                helperText={errors.email}
            />
            <TextField
                label="Password"
                variant="filled"
                type="password"
                value={password}
                onChange={passwordOnChange}
                required
                error={errors.password}
                helperText={errors.password}
            />

            <Button style={{padding: "20px 60px", fontSize: "20px"}} type="submit" variant="contained" size="large"  disabled={handlingSubmit || !_.isEmpty(errors)}>
                LOG IN
            </Button>
            {
                errorMessage && <div>{errorMessage}</div>
            }
        </form>
        <div className="formLink"><Link to={forgotUrl} component={RouterLink} underline="hover"><Button>Forgotten password?</Button></Link>
        </div>
        <div className="formLink"><Link
            to={{
                pathname:createUrl,
                state: {redirect: signInRedirect}
            }}
            component={RouterLink}
            underline="hover"><Button>Create account</Button></Link></div>
        </div>
        </Container>
        <div className="spacer"/>
    </>;
}

export default SignIn;
