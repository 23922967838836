import React from "react"

//MATERIALS
import IconButton from '@mui/material/IconButton';

//INTERNAL IMPORTS
import { useLocale } from '../../api-locale';

//EXTERNAL IMPORTS
import CurrencyFlag from 'react-currency-flags';


export const CurrencyIcon = (props) => {
    const {user, handleCurrencyMenuOpen} = props;
    const {locale } = useLocale(user);

    if (locale) {
        return (
            <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={"currency-menu"}
                aria-haspopup="true"
                className={"jpIcon"}
                color="secondary"
                onClick={handleCurrencyMenuOpen}
                style={{marginRight: 10}}
                size="large">
                <CurrencyFlag currency={locale.currencyCode} width={24} />
            </IconButton>
        );
    }
    return (null);
}