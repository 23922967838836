
const BlogData = [
    {
      title: "Mother's Day Dog Gifts: Your Guide to Doggy Gift Giving",
      imageSrc: "/images/blog/2023/mothersday24/mothersday.webp",
      imageAlt: "Mother's Day Dog Gifts: Your Guide to Doggy Gift Giving with Jigpaws",
      link: "/blog/mothers-day-dog-gift/",
      description: "If you’re looking to get a Dog Mum a gift for Mother’s Day, or you’re looking on behalf of your four-legged friend, then you’re in the right place."
    },
    {
      title: "DOG BIRTHDAY GIFT INSPIRATION: 20 UNIQUE IDEAS FROM JIGPAWS",
      imageSrc: "/images/blog/2023/adorable-little-corgi-dog-with-gift-box.jpg",
      imageAlt: "DOG BIRTHDAY GIFT INSPIRATION: 20 UNIQUE IDEAS FROM JIGPAWS",
      link: "/blog/dog-birthday-gift-inspiration/",
      description: "At Jigpaws, we recognise the importance of making the most of your dog's birthday and finding a gift that both you and your dog will cherish."
    },
    {
      title: "LEARN HOW TO MAKE A BIRTHDAY CARD FOR A CAT WITH JIGPAWS",
      imageSrc: "/images/blog/2023/LEARN-HOW-TO-MAKE-A-BIRTHDAY-CARD-FOR-A-CAT-WITH-JIGPAWS.jpg",
      imageAlt: "HOW-TO-CREATE-UNIQUE-BIRTHDAY-CARDS-FOR-A-CAT",
      link: "/blog/make-a-birthday-card-for-a-cat/",
      description: "Creating a birthday card for a cat (or for someone with an adorable feline companion) is easier than ever with the help of Jigpaws."
    },
    {
      title: "TOP 10 GIFTS FOR DOG LOVERS IN 2024 JIGPAWS",
      imageSrc: "/images/blog/2023/Top-10-Gifts-For-Dog-Lovers-In-2024.jpg",
      imageAlt: "HOW-TO-CREATE-UNIQUE-BIRTHDAY-CARDS-FOR-A-CAT",
      link: "/blog/top-10-gifts-for-dog-lovers-in-2024/",
      description: "At Jigpaws, we’re here to give you some of the best gift ideas for that special dog lover in your life. We’re here to make sure you don’t fall into the trap of buying a gift intended for the dog instead of the human!"
    },
    
  ];
  
  export default BlogData;
  