import React, {useState} from 'react';

import {Container, Typography} from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Link from "@mui/material/Link";

import {getAuth, signOut} from "firebase/auth";
import {app, upgradeAnonymousAccount} from "./firebase";
import {useAuthState} from "react-firebase-hooks/auth";
import {Link as RouterLink, useHistory, useLocation} from "react-router-dom";
import {useFormStyles, useQuery} from "./utils";
import _ from "lodash";


const buttonStyles = {
    padding: "20px 60px",
    fontSize: "20px",
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "0.05em"
}


const CreateAccount = () => {
    const formClasses = useFormStyles();
    // create state variables for each input
    const query = useQuery();
    const initialEmail = query.get("email");
    const [displayName, setDisplayName] = useState('');
    const [email, setEmail] = useState(initialEmail);
    const [password, setPassword] = useState('');
    // const [errors, setErrors] = useState({});
    const auth = getAuth(app);

    //todo
    const [user, error] = useAuthState(auth);
    // const [user, loading, error] = useAuthState(auth);

    const [handlingSubmit, setHandlingSubmit] = useState(false);
    const [errors, setErrors] = useState({});
    const [errorCode, setErrorCode] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const location = useLocation();
    const history = useHistory();
    const signInRedirect = _.get(location, "state.redirect", "/create");


    const handleSubmit = e => {
        setHandlingSubmit(true);
        e.preventDefault();

        console.log("previousUID", user.uid);

        upgradeAnonymousAccount(user, displayName, email, password)
            .then(() => {
                history.push(signInRedirect);
            })
            .catch((error) => {
                console.log(error);
                setErrorCode(error.code);
                switch (error.code) {
                    case 'auth/email-already-in-use':
                        setErrors({'email': 'Email already in use, try signing in...'})
                        break;
                    case 'auth/weak-password':
                        setErrors({'password': 'Password should be at least 6 characters.'});
                        break;
                    default:
                        setErrorMessage(error.message);
                }

            }).finally(() => {
            setHandlingSubmit(false);
        });
    };

    const handleSignOut = e => {
        e.preventDefault();
        signOut(auth).then(() => {
            console.log("signed out... from sign in page");
        }).catch((error) => {
            console.log("failed sign out... from sign in page");

        });
    };

    const passwordOnChange = e => {
        e.preventDefault();
        const value = e.target.value;
        if (value && value.length > 5) {
            setErrors(_.omit(error, "password"))
        }
        setPassword(value);
    }

    const signInUrl = (email && email.length > 3) ? `/sign-in?email=${email}` : `/sign-in`;

    if (user && user.isAnonymous) {
        return <>
   <Container className={"productsHome bgGray"} disableGutters={true} maxWidth={false}>
                    <div className="spacer"/>
                    <div><h1>Create Account</h1></div>
                    <div className="spacer"></div>
                    <div className="jpDivider bgBlue"/>
                </Container>
            <Container disableGutters={true} className={formClasses.container}>
                <div className="spacer"/>
                <div className="formBox">
                    <form className={formClasses.root} onSubmit={handleSubmit}>
                        <TextField
                            label="Name"
                            variant="filled"
                            value={displayName}
                            onChange={e => setDisplayName(e.target.value)}
                            required
                        />
                        <TextField
                            label="Email"
                            variant="filled"
                            type="email"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            required
                            error={errors.email}
                            helperText={errors.email}
                        />
                        <TextField
                            label="Password"
                            variant="filled"
                            type="password"
                            value={password}
                            onChange={passwordOnChange}
                            required
                            error={errors.password}
                            helperText={errors.password}
                        />

                        <Typography style={{"width":"100%", margin: "8px"}}>By signing up you are agreeing to the Jigpaws <a target="_blank"
                                                                                     href="legal/terms-and-conditions">terms
                            and conditions</a> and <a target="_blank" href="/legal/privacy-policy">privacy
                            policy</a></Typography>

                        <Button style={buttonStyles} className="filled" type="submit" variant="contained" size="large" color="primary"
                                disabled={handlingSubmit || !_.isEmpty(errors)}>
                            Create Account
                        </Button>
                        <div className="formLink">
                            <Typography>Already have an account?</Typography>

                            <Link
                                component={RouterLink}
                                to={{
                                    pathname: signInUrl,
                                    state: {redirect: signInRedirect}
                                }}
                                underline="hover">Sign In</Link>
                        </div>
                        {/*<Button className="filled" variant="contained" size="large" color="primary"*/}
                        {/*        component={RouterLink} to="/create">*/}
                        {/*    CONTINUE AS GUEST*/}
                        {/*</Button>*/}
                    </form>
                </div>
            </Container>
            <div className="spacer"/>
        </>;
    }
    if (user && !user.isAnonymous) {
        return <div>Logged in as {user.displayName} ({user.email}) <Link onClick={handleSignOut} underline="hover">Sign out</Link></div>;
    }
    return (null);
};

export default CreateAccount;
