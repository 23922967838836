import styled from "styled-components"

export const Body = styled.div`
    font-size: 20px;
    font-weight: 500;
    letter-spacing: "0.03em";
    margin: 100px;
    

    @media (max-width: 960px) {
        font-size: 16px;
    }
`;

export const BigBody = styled.div`
    font-size: 20px;
    font-weight: 500;
    letter-spacing: "0.05em";
    max-width: 85%;
    padding-top: 5px  

`;

export const StyledP = styled.p`
font-size: 15px;

@media (min-width: 1280px) {
  font-size: 20px;
}
`;

export const BigP = styled.p`
font-size: 20px;
font-weight: 500;
letter-spacing: 0.03em
`;




export const CustomButton = styled.button`
    font-family: Facundo, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    width: ${props => props.type === 'smallwhite' ? '250px' : '350px'};
    text-transform: uppercase;
    padding: ${props => props.type === 'smallwhite' ? '10px 30px' : '20px 60px'};
    font-size: 20px;
    font-weight: ${props => props.type === 'smallwhite' ? "400" : "500"};
    line-height: 20px;
    letter-spacing: 0.05em;
    background-color: ${props => props.type === 'smallwhite' ? '#99BFC2' : '#413E40'};
    color: white;
    border: ${props => props.type === 'smallwhite' ? '0.08em solid white' : "0.08em solid transparent"};
    transition: all 0.3s ease;
    cursor: pointer;

    &:hover {
        background-color: white;
        color: ${props => props.type === 'smallwhite' ? '#99BFC2' : '#413E40'};
        border: ${props => props.type === 'smallwhite' ? '0.08em solid transparent' : '0.08em solid #413E40'};
    }

    @media (min-width: 960px) {
        border-width: 0.1em; 

        &:hover {
            border-width: 0.1em; // Maintain 0.1em border thickness on hover for large screens
        }
    }
`;

export const StyledButton = styled.button`
  padding:  20px 60px;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.05em;
  border: 0.05em solid white;
  text-transform: uppercase;
  background-color: ${props => {
    if (props.type === 'bigwhite') return '#99BFC2';
    if (props.type === 'bigwhitealternate') return '#85A2AD'; 
    return 'transparent'; 
  }};
  color: white;
  transition: all 0.3s ease;
  cursor: pointer

  &:hover {
    background-color: white;
    color: ${props => props.type === 'bigwhite' ? '#99BFC2' : '#85A2AD'};
    border: 0.05em solid white
  }

  @media (min-width: 960px) {
    border-width: 0.1em; 
    &:hover {
        border-width: 0.1em; 
    }
  }

  @media (max-width: 600px) {
    padding: ${props => props.type === 'bigwhite' ? '15px 30px' : '15px 30px'};
    border-width: 1px; 
    &:hover {
        border-width: 1px; 
    }
  }
`;

export const CartButton = styled.button`
    font-family: Facundo, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    text-transform: uppercase;
    padding: 20px 60px;
    font-size: 20px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.05em;
    background-color: #413E40;
    color: white;
    border: 1px solid transparent; 
    transition: all 0.3s ease;
    cursor: pointer;

    &:hover {
        background-color: #85A2AD;
        color: #413E40;
        border: 1px solid #413E40;
    }
`;