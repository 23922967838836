import React from "react";

const petProps = {
    title: "Create personalised pet gifts with Jigpaws",
    bodyText1: "Upload a picture of your beloved pet using our easy-to-use portal and choose from one of our many products. You can get your newly personalised pet gift shipped out for free to anywhere in the UK and beyond!",
    bodyText2: "See how you can share a little piece of the family with our range of pet options below!",
    buttonText: "CREATE NOW",
    buttonHref: "/create-personalised-pet-photo-gifts"
};

const dogProps = {
    title: "Personalised pet gifts for dogs",
    bodyText1: "Here at Jigpaws, we believe that our dogs are a part of your family. What better way to celebrate your four-legged friends than with a comfy pillow, intricate jigsaw puzzle or photo tile?",
    bodyText2: "We offer FREE SHIPPING on our custom, hand-printed jigsaws. See the full range of international and domestic shipping choices for journals, cards, photo tiles and more at checkout.",
    buttonText: "Read More",
    buttonHref: "/gifts/dogs"
};

const catProps = {
    title: "Personalised pet gifts for cats",
    bodyText1: "If you or someone you know would love to receive a fully personalised pet gift featuring their cat, then look no further. Simply upload any picture of your feline friend to Jigpaws using our easy system and see it on one of our products instantly.",
    bodyText2: "We offer FREE INTERNATIONAL SHIPPING on our custom, hand-printed jigsaws. See the full range of your shipping choices for journals, cards, photo tiles and more at checkout.",
    buttonText: "READ MORE",
    buttonHref: "/gifts/cats"
};

const otherProps1 = {
    title: "Personalised pet gifts for every animal",
    bodyText1: "Have a horse, turtle or budgie? They can still feature on your gift here at Jigpaws with our full list of products. You can personalise:",
    buttonText: "READ MORE",
    buttonHref: "/gifts/pets"
};

const otherProps2 = {
    title: "Personalised pet gifts for every animal",
    bodyText1: "Have a horse, turtle or budgie? They can still feature on your gift here at Jigpaws with our full list of products. You can personalise:",
    bodyText2: (
        <>
            <p>• Cards</p>
            <p>• Journals</p>
            <p>• Photo Tiles</p>
            <p>• Photo Books</p>
            <p>• Cushions</p>
            <p>• Jigsaws</p>
        </>
    ),
    buttonText: "READ MORE",
    buttonHref: "/gifts/pets"
};

export {petProps, dogProps, catProps, otherProps1, otherProps2}