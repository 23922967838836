import {useEffect, useMemo, useState} from "react";
import { productConverter} from "./models";
import _ from 'lodash';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';


let BASE_URL = "";

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // dev code
    BASE_URL = process.env.REACT_APP_BASE_URL;
} else {
    // production code
    BASE_URL = "/";
}

export const createUrl = (path) => `${BASE_URL}${path}`;

const useApiResult = (request, transformer) => {
    const [results, setResults] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetch(...request)
            .then(async (response) => {
                if (response.ok) {
                    let result = await response.json();
                    if (transformer) {
                        result = _.transform(result, function(result, value, key) {
                            if (transformer.hasOwnProperty(key)) {
                                result[key] = transformer[key](value);
                            } else {
                                result[key] = value;
                            }
                        }, {});
                    }
                    setResults(result);
                    setError(null);
                } else {
                    setError(await response.text());
                }
            })
            .catch((err) => {
                setError(err.message);
            });
    }, [request]);

    return [results, error];
};

const makeProductRequest = (currency) => [
    createUrl(`api/products2?currency=${currency}`),
    {
        method: "GET",
    }
];

export const useProducts = (state = 'default', currency="GBP") => {
    const request = useMemo(() => makeProductRequest(currency), [currency]);
    const transformer = {
        'products': function (value) {
            return _.map(value, function (data) {
                return productConverter.fromApi(data);
            });
        }
    };
    const [results, error] = useApiResult(request, transformer);

    if (results) {
        let photobookCount = 0;
        results.products = _.filter(results.products, (product) => {
            if (state === 'default') {
                return product.category !== "photobook";
            }
            if (state === 'photobooks') {
                return product.category === "photobook";
            }
            if (state === 'allWithSinglePhotobook') {
                let isPhotobook = product.category === "photobook";
                if (!isPhotobook) {
                    return true;
                }
                if (isPhotobook && photobookCount === 0) {
                    photobookCount += 1;
                    return true;
                }
                return false;
            }
            return true; // state === 'all' or whatever really..
        });
    }
    return [results, error];
};

const makeCountriesRequest = () => [
    createUrl("api/countries"),
    {
        method: "GET",
    }
];

export const useCountries = () => {
    const request = useMemo(() => makeCountriesRequest(), []);
    return useApiResult(request);
};


const makeCurrenciesRequest = () => [
    createUrl("api/currencies2"),
    {
        method: "GET",
    }
];

export const useCurrencies = (user) => {
    const request = useMemo(() => makeCurrenciesRequest(), [user]);
    // const transformer = {
    //     'data': function(value) {
    //         return _.map(value, function(data) {
    //            return countryConverter.fromApi(data);
    //         });
    //     }
    // };
    return useApiResult(request);
};

const makeCurrencyRequest = () => [
    createUrl("api/currency"),
    {
        method: "GET",
    }
];

export const useDiscount = (user, basket) => {
     const [discount, setDiscount] = useState(null);
     const [discountLoading, setDiscountLoading] = useState(true)

    useEffect(() => {
        setDiscountLoading(true)
        if (!basket) {
            setDiscount(null);
            return;
        }
        if (basket.discount_code) {
            jigpawAPI.get_discount(user, basket.discount_code).then((data) => {
                setDiscount(data.discount);
            }).catch((err) => {
                setDiscount(null)
                console.log("Error getting discount", err.message);
            }).finally(()=>{
                setDiscountLoading(false);
            });
        } else {
            setDiscountLoading(false)
            setDiscount(null);
        }


    }, [user, basket]);

    return [discount, discountLoading]
}


export const jigpawAPI = {
    hand: (user, email) => {
        // make sure to re throw() the error in catch so they bubble.
        return user.getIdToken().then((token) => {
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            return axios.post(createUrl('api/anonymous/hand'), {email: email}, config)
                .then(() => {
                    console.log("success api/anonymous/hand");
                })
                .catch((error) => {
                    console.log("error api/anonymous/hand", error);
                    throw( error );
                });
        });
    },
    shake: (user, uid) => {
        return user.getIdToken().then((token) => {
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            return axios.post(createUrl('api/anonymous/shake'), {uid: uid}, config)
                .then(() => {
                    console.log("success api/anonymous/shake");
                })
                .catch((error) => {
                    console.log("error api/anonymous/shake", error);
                    throw( error );
                });
        });
    },
    shippingOptions: (user, countryCode, currency) => {
        return user.getIdToken().then((token) => {
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            return axios.post(createUrl('shipping_options'), {
                countryCode: countryCode,
                currency: currency,
            }, config)
                .then((response) => {
                    console.log("success api/shipping_options");
                    //step 2
                    // console.log(JSON.stringify(response.data,null,2))
                    return response.data;
                })
                .catch((error) => {
                    console.log("error api/shipping_options", error);
                    throw( error );
                });
        });
    },
    create_payment_intent: (user, shippingAddress, shippingMethod, expectedAmount, currency) => {

        const idempotencyKey = uuidv4();

        return user.getIdToken().then((token) => {
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            return axios.post(createUrl('api/create_payment_intent'), {
                ...shippingAddress,
                shipping: shippingMethod,
                expected_amount: expectedAmount,
                currency: currency,
                idempotencyKey: idempotencyKey
            }, config)
                .then((response) => {
                    console.log("success api/create_payment_intent", response);
                    return response.data;
                })
                .catch((error) => {
                    console.log("error api/create_payment_intent", error);
                    throw( error );
                });
        });
    },
    update_currency: (user, currency) => {
        return user.getIdToken().then((token) => {
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            return axios.post(createUrl('api/currency'), {
                currency: currency,
            }, config)
                .then((response) => {
                    console.log("success api/currency", response);
                    return response.data;
                })
                .catch((error) => {
                    console.log("error api/currency", error);
                    throw( error );
                });
        });
    },
    apply_discount: (user, code) => {
        return user.getIdToken().then((token) => {
            const config = {
                headers: {Authorization: `Bearer ${token}`}
            };
            return axios.post(createUrl('api/discount-code'), {
                code: code,
            }, config)
                .then((response) => {
                    console.log("success api/discount-code", response);
                    return response.data;
                })
                .catch((error) => {
                    console.log("error api/discount-code", error);
                    throw (error);
                });
        });
    },
    get_discount: (user, code) => {
        return user.getIdToken().then((token) => {
            const config = {
                headers: {Authorization: `Bearer ${token}`},
                params: {code: code},
            };
            return axios.get(createUrl('api/discount-code'), config)
                .then((response) => {
                    console.log("success api/discount-code", response);
                    return response.data;
                })
                .catch((error) => {
                    console.log("error api/discount-code", error);
                    throw (error);
                });
        });
    },
};

