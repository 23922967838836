import React from "react";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    square: {
        paddingBottom: "100%",
        display: "block",
        position: "relative"
    },
    squareContent: {
        position: "absolute",
        left: 0,
        top: 0,
        height: "100%",
        width: "100%",
        // objectFit: "scale-down"
    },
}));

export const Square = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.square}>
            <div className={classes.squareContent}>
                {props.children}
            </div>
        </div>
    );
}
