import React from 'react'

export default function TrustPilotWidget() {
  return (
    <>
        <div className="spacerSmall" />
        <div 
            className="trustpilot-widget" 
            data-locale="en-GB" 
            data-template-id="5419b6a8b0d04a076446a9ad" 
            data-businessunit-id="601ff4e4e8edca00013d3387" 
            data-style-height="24px" 
            data-style-width="100%" 
            data-theme="light" 
            data-min-review-count="10"
        >
            <a href="https://uk.trustpilot.com/review/jigpaws.com" target="_blank" rel="noopener noreferrer">Trustpilot</a>
        </div>
        <div className="spacerSmall" />
    </>
  )
}