/* React */
import React from "react";
import Grid from "@mui/material/Grid";
import {Square} from "./global/components/Square";
import {Container} from "@mui/material";
import Button from "@mui/material/Button";
import BalanceText from "react-balance-text";
import Hidden from "@mui/material/Hidden";
import ShippingFooter from "./ShippingFooter/ShippingFooter";
import CenterGrid from "./CenterGrid";
import { useStyles } from "./utils";



const ProductHome = () => {
    const classes = useStyles();
    return <>
        <Container className={"productsHome bgGray"} align="center" disableGutters={true} maxWidth={false}>
            <div className="spacer"/>
            <div className="headerContainer">
                <div><h1><BalanceText>CREATE A PERSONALISED JIGPAWS GIFT</BalanceText></h1></div>
                <div className="body leader"><BalanceText>Upload a photograph of your adorable pets – cat, dog, rabbit – or favourite fur-baby and create a personalised Jigpaws gift to share with friends and family.</BalanceText>
            </div>
            <div className="body leader">All items ship globally – free UK shipping.</div>
                </div>
            <div className="spacer"></div>
            <div className="jpDivider bgBlue"/>

        </Container>
        <Container maxWidth="xl" disableGutters={true} spacing={0}>

            <Grid container spacing={0} className="marginBottomFix">
                <Grid item xs={12} md={6} spacing={0}>
                    <square>
                        <img className="jpLine" src="/images/products/2022/jigpawsDogCard.png"
                             alt="Jigpaws personalised greetings card with pet dog"
                             style={{"width": "100%"}}/>
                    </square>
                </Grid>
                <Grid item xs={12} md={6} spacing={0} className="jpTile bgWhite">
                    <Hidden smUp="true">
                        <CenterGrid>
                            <Grid>
                                <h2>Cards</h2>
                                <div className="spacerMed"/>
                                <div className="body">
                                    <p>Create personalised fine art Jigpaws greeting card. Each printed on premium fine art paper (324gsm) come delivered to your door together with an envelope. Simply post to make your friends and family smile.</p>
                                </div>
                                <div className="spacerMed"/>
                                <Button size="small" variant="outlined" href="/create-personalised-pet-photo-gifts">CREATE NOW</Button>
                                <div className="spacerMed"/>
                            </Grid>
                        </CenterGrid>
                    </Hidden>
                        <Hidden smDown={true}>
                    <Square>

                        <CenterGrid>
                            <div className="jpTileInner jpProductInner">
                                <h2>Cards</h2>
                                <div className="spacerMed"/>
                                <div className="body">
                                    <BalanceText><p>Create personalised fine art Jigpaws greeting card. Each printed on premium fine art paper (324gsm) come delivered to your door together with an envelope. Simply post to make your friends and family smile.</p></BalanceText>

                                </div>
                                <div className="spacerMed"/>
                                <Button size="small" variant="outlined" href="/create-personalised-pet-photo-gifts">CREATE NOW</Button>
                                <div className="spacerMed"/>

                            </div>
                        </CenterGrid>
                    </Square></Hidden>
                </Grid>
            </Grid>
            {/*<div className="spacerMed"/>*/}
            <Grid container spacing={0} className="marginBottomFix">
                <Grid item xs={12} md={6} className="jpTile bgWhite" order={{xs: 1, sm: 1}}>
                    <Hidden smUp="true">
                        <CenterGrid>
                        <Grid>
                            <div className="jpTileInner">
                                <h2>Journal</h2>
                                <div className="spacerMed"/>
                                <div className="body">
                                    <p>Create personalised hardcover Jigpaws journal. Each journal comes with 64 pages of ruled, graph or blank high quality paper pages. Perfect for making a note or two.</p>
                                </div>
                                <div className="spacerMed"/>
                                <Button size="small" variant="outlined" href="/create-personalised-pet-photo-gifts">CREATE NOW</Button>
                                <div className="spacerMed"/>

                                    </div>
                        </Grid></CenterGrid></Hidden>
                        <Hidden smDown={true}>
                    <Square>
                        <CenterGrid>
                            <div className="jpTileInner jpProductInner">
                                <h2>Journal</h2>
                                <div className="spacerMed"/>
                                <div className="body">
                                    <BalanceText><p>Create personalised hardcover Jigpaws journal. Each journal comes with 64 pages of ruled, graph or blank high quality paper pages. Perfect for making a note or two.</p></BalanceText>
                                </div>
                                <div className="spacerMed"/>
                                <Button size="small" variant="outlined" href="/create-personalised-pet-photo-gifts">CREATE NOW</Button>
                                <div className="spacerMed"/>

                            </div>
                        </CenterGrid>
                    </Square></Hidden>
                </Grid>
                <Grid item xs={12} md={6} order={{xs: 2, sm: 2}} className={classes.moveToFront}>
                    <square>
                        <img className="jpLine" src="/images/products/2022/jigpawsCatJournal2.png"
                             alt="Jigpaws personalised gift journal with pet cat" style={{"width": "100%"}}/>
                    </square>
                </Grid>
            </Grid>
            {/*<div className="spacerMed"/>*/}
            <Grid container spacing={0} className="marginBottomFix">
                <Grid item xs={12} md={6}>
                    <square>
                        <img className="jpLine" src="/images/products/2022/jigpawsDogPhotoTile.png"
                             alt="Jigpaws personalised gift tile product with pet dog"
                             style={{"width": "100%"}}/>
                    </square>
                </Grid>
                <Grid item xs={12} md={6} className="jpTile bgWhite">
                    <Hidden smUp="true">
                        <CenterGrid>
                        <Grid>
                            <div className="jpTileInner">
                                <h2>Photo Tile</h2>
                                <div className="spacerMed"/>
                                <div className="body">
                                    <p>Create stunning photo walls with a Jigpaws framed photo tile. Whether free standing or hung on the wall, a Jigpaws framed photo tile will let your fur-baby shine bright in any room. Each come ready with a mark free adhesive hanger, allowing you to reposition your framed tile with ease...!</p>
                                </div>
                                <div className="spacerMed"/>
                                <Button size="small" variant="outlined" href="/create-personalised-pet-photo-gifts">CREATE NOW</Button>
                                <div className="spacerMed"/>

                                    </div>
                        </Grid></CenterGrid></Hidden>
                        <Hidden smDown={true}>
                    <Square>
                        <CenterGrid>
                            <div className="jpTileInner jpProductInner">
                                <h2>Photo Tile</h2>
                                <div className="spacerMed"/>
                                <div className="body">
                                    <BalanceText><p>Create stunning photo walls with a Jigpaws framed photo tile. Whether free standing or hung on the wall, a Jigpaws framed photo tile will let your fur-baby shine bright in any room. Each come ready with a mark free adhesive hanger, allowing you to reposition your framed tile with ease...!</p></BalanceText>

                                </div>
                                <div className="spacerMed"/>
                                <Button size="small" variant="outlined" href="/create-personalised-pet-photo-gifts">CREATE NOW</Button>
                                <div className="spacerMed"/>

                            </div>
                        </CenterGrid>
                    </Square></Hidden>
                </Grid>
            </Grid>
            {/*<div className="spacerMed"/>*/}
            <Grid container spacing={0} className="marginBottomFix">
                <Grid item xs={12} md={6} className="jpTile bgWhite">
                    <Hidden smUp="true">
                        <CenterGrid>
                        <Grid>
                            <div className="jpTileInner">
                                <h2>Cushions</h2>
                                <div className="spacerMed"/>
                                <div className="body">
                                    <p>Create a personalised suede Jigpaws cushion to snuggle up with. Each individually handmade to order, using your choice of image. These beautifully crafted throw cushions will make any home, homely.</p>

                                </div>
                                <div className="spacerMed"/>
                                <Button size="small" variant="outlined" href="/create-personalised-pet-photo-gifts">CREATE NOW</Button>
                                <div className="spacerMed"/>

                                    </div>
                        </Grid></CenterGrid></Hidden>
                        <Hidden smDown={true}>
                    <Square>
                        <CenterGrid>
                            <div className="jpTileInner jpProductInner">
                                <h2>Cushions</h2>
                                <div className="spacerMed"/>
                                <div className="body">
                                    <BalanceText><p>Create a personalised suede Jigpaws cushion to snuggle up with. Each individually handmade to order, using your choice of image. These beautifully crafted throw cushions will make any home, homely.</p></BalanceText>

                                </div>
                                <div className="spacerMed"/>
                                <Button size="small" variant="outlined" href="/create-personalised-pet-photo-gifts">CREATE NOW</Button>
                                <div className="spacerMed"/>

                            </div>
                        </CenterGrid>
                    </Square></Hidden>
                </Grid>
                <Grid item xs={12} md={6} className={classes.moveToFront}>
                    <square>
                        <img className="jpLine" src="/images/products/2022/jigpawsCatCushion.png"
                             alt="Jigpaws personalised gift cushion with pet cat" style={{"width": "100%"}}/>
                    </square>
                </Grid>
            </Grid>
            {/*<div className="spacerMed"/>*/}
            <Grid container spacing={0} className="marginBottomFix">
                <Grid item xs={12} md={6}>
                    <square>
                        <img className="jpLine" src="/images/products/2022/jigpawsDogJigsaw.jpg"
                             alt="Jigpaws personalised gift jigsaw puzzle with pet dog"
                             style={{"maxWidth": "100%"}}/>
                    </square>
                </Grid>
                <Grid item xs={12} md={6} className="jpTile bgWhite">
                    <Hidden smUp="true">
                        <CenterGrid>
                        <Grid>
                                                                    <div className="jpTileInner">

                                  <h2>Jigsaws</h2>
                                <div className="spacerMed"/>
                                <div className="body">
                                    <BalanceText><p>Create a personalised Jigpaws jigsaw puzzle. Each hand printed to order with full edge to edge printing to ensure your photo is printed perfectly crisp and vibrant with colour.</p></BalanceText>
                                    <BalanceText><p>Beautifully presented in a premium box and protected in a velvet pouch. Perfect for a quiet night in.</p></BalanceText>

                                </div>
                                <div className="spacerMed"/>
                                <div><h3>Coming Soon!</h3></div>

                                <div className="spacerMed"/>
                                                                    </div>

                        </Grid></CenterGrid></Hidden>
                        <Hidden smDown={true}>
                    <Square>
                        <CenterGrid>
                            <div className="jpTileInner jpProductInner">
                                <h2>Jigsaws</h2>
                                <div className="spacerMed"/>
                                <div className="body">
                                    <BalanceText><p>Create a personalised Jigpaws jigsaw puzzle. Each hand printed to order with full edge to edge printing to ensure your photo is printed perfectly crisp and vibrant with colour.</p></BalanceText>
                                    <BalanceText><p>Beautifully presented in a premium box and protected in a velvet pouch. Perfect for a quiet night in.</p></BalanceText>

                                </div>
                                <div className="spacerMed"/>
                                <div><h3>Coming Soon!</h3></div>

                                <div className="spacerMed"/>

                            </div>
                        </CenterGrid>
                    </Square></Hidden>
                </Grid>
            </Grid>
            {/*<div className="spacerMed"/>*/}

        </Container>


        <ShippingFooter />
    </>;
};

export default ProductHome
