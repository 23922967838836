import React from 'react'

export default function PaymentInfo() {
  return (
    <>
        <div className="paymentInfo">All payments processed by</div>
        <div className="spacerSmall" />
        <div className="paymentInfo">
        <img src="/images/svg/Stripe-blurple.svg" height="35px" alt="stripe" />
        <img src="/images/svg/PayPal.svg" height="35px" alt="paypal" />
        <img src="/images/svg/google-pay-mark-1.png" height="35px" alt="google pay" />
        <img src="/images/svg/Apple_Pay_Mark_RGB_041619.svg" height="35px" alt="apple pay" />
        </div>
    </>
  )
}
