import React from 'react';
import {makeStyles} from '@mui/styles';
import ImageEditorComponent from "../components/ImageEditorComponent";
import {useHistory, useParams} from "react-router-dom";
import {useAuthState} from "react-firebase-hooks/auth";
import {app, auth} from "../../firebase";
import {doc, getFirestore} from "firebase/firestore";
import {useDocumentData} from "react-firebase-hooks/firestore";
import {useProducts} from "../../API";
import _ from "lodash";
import {jigpawConverter} from "../../models";
import {Container} from "@mui/material";
import {useLocale} from "../../api-locale";
// Import the editor styles


// const useStyles = makeStyles((theme) => ({
//     margin: {
//         margin: theme.spacing(1),
//     },
//     extendedIcon: {
//         marginRight: theme.spacing(1),
//     },
// }));

const ImageEditorPageCurrency = ({user, currency}) => {
    const {productId, jigpawId} = useParams();
    const db = getFirestore(app);
    const ref = doc(db, "jigpaws", jigpawId).withConverter(jigpawConverter);
    const [jigpaw, jigpawLoading, jigpawError] = useDocumentData(
        ref
    )
    const [productResponse, error] = useProducts('default', currency);
    const history = useHistory();

    let product = null;
    if (productResponse) {
        product = _.find(productResponse.products, {'id': productId});
    }

    if (jigpaw && product) {

        const callBack = (jigpaw) => {
            // console.log("redirect... after image edit", `/product/${product.category}/${_.get(jigpaw, "uuid")}`);
            if (jigpaw) {
                history.push(`/product/${product.category.replace('_', '-')}/${jigpaw.linkUUID()}`);
            }
        };

        return (
                <Container maxWidth="md" className="header-top-padding">
                <ImageEditorComponent user={user} product={product} jigpaw={jigpaw} jigpawCreated={callBack}></ImageEditorComponent>
                    </Container>
        )
    }
    return (null);
}

const ImageEditorPageUser = ({user}) => {
    const {locale, loading, e} = useLocale(user);

    if (locale) {
        return (
            <ImageEditorPageCurrency user={user} currency={locale.currencyCode}></ImageEditorPageCurrency>
        )
    }
    return (null);
}


const ImageEditorPage = () => {
    const [user, authLoading, authError] = useAuthState(auth);

    if (user) {
        return (
            <ImageEditorPageUser user={user}></ImageEditorPageUser>
        )
    }
    return (null);
}

export default ImageEditorPage


