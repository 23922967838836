//React
import React from "react";

//Hooks, utils, data
import {useLocale} from "../api-locale";
import { useProducts } from "../API";
import {jigpawsDineroFormatted} from "../utils";

//External Imports
import Dinero from "dinero.js";
import _ from "lodash";



export const slider_settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dotColor: "white"

};


export const ProductPrice = ({user, productId, startingFrom = false, each = false}) => {

    if (user) {
        return (
            <ProductPriceCurrency user={user} productId={productId} startingFrom={startingFrom}></ProductPriceCurrency>)
    }
    return (null)
}


export const ProductPriceCurrency = ({user, productId, startingFrom, each}) => {
    const {locale, loading, e} = useLocale(user);
    if (locale) {
        return (<ProductPriceFinal productId={productId} currency={locale.currencyCode} startingFrom={startingFrom}
                                   each={each}></ProductPriceFinal>);
    }
    return (null);

}


export const ProductPriceFinal = ({productId, currency, startingFrom, each = false}) => {
    const [productResponse,] = useProducts('all', currency);

    let product = null;
    if (productResponse) {
        product = _.find(productResponse.products, {'id': productId});
    }
    if (product) {
        const priceFormatted = jigpawsDineroFormatted(Dinero({amount: product.price, currency: currency}));

        return (<div className="jpProductCost">{startingFrom && "from – "}<span
                className="bold">{priceFormatted}</span> {each && "each"}</div>)
    }
    return (null);
}

export const ProductPriceFallback = ({productId}) => {
    if (productId === "gloss-greeting-card") {
        return (<div className="jpProductCost"><span className="bold">£4</span> / <span
                className="bold">$5*</span> / <span className="bold">€5*</span> each</div>)
    }
    if (productId === "plain-journal") {
        return (<div className="jpProductCost"><span className="bold">£14</span> / <span
                className="bold">$19*</span> / <span className="bold">€17*</span></div>)
    }
    if (productId === "photo-tile") {
        return (<div className="jpProductCost"><span className="bold">£19</span> / <span
                className="bold">$25*</span> / <span className="bold">€23*</span></div>)
    }

    if (productId === "suede-cushion-12x12") {
        return (<div className="jpProductCost">from – <span className="bold">£26</span> / <span
                className="bold">$32*</span> / <span className="bold">€29*</span></div>)
    }

    if (productId === "photobook-14x14") {
        return (<div className="jpProductCost">from – <span className="bold">£22</span> / <span
                className="bold">$29*</span> / <span className="bold">€27*</span></div>

        )
    }

    if (productId === "jigsaw-500-50x38") {
        return (<div className="jpProductCost">from - <span className="bold">£28</span> / <span
                className="bold">$39*</span> / <span className="bold">€35*</span></div>)
    }
}
