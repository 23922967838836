//REACT
import React from "react";

//MATERIALS
import { Container } from "@mui/material";


//EXTERNAL IMPORTS
import BalanceText from "react-balance-text";
import Helmet from "react-helmet";
import ShippingFooter from "../ShippingFooter/ShippingFooter";

//COMPONENTS
import BlogItem from "./BlogItem";
import { BigP } from "../styledComponents"

//HOOKS, UTILS, DATA
import BlogData from "./BlogData";




const BlogsPage = () => {
  return (
    <>
      <Helmet>
        <title>Jigpaws – Blog</title>
        <meta
          name="description"
          content="Read all about the latest gift ideas for pet and animal lovers. "
        />
        <link rel="canonical" href="https://www.jigpaws.com/blog/" />
        <meta property="og:url" content="https://www.jigpaws.com/blog/" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="content" />
        <meta
          property="og:description"
          content="Read all about the latest gift ideas for pet and animal lovers."
        />
        <meta property="og:image" content="content" />
      </Helmet>
      <Container
        className={"productsHome bgGray"}
        align="center"
        disableGutters={true}
        maxWidth={false}
      >
        <div className="spacer" />
        <div className="headerContainer">
          <div>
            <h1>
              <BalanceText>Jigpaws Blog</BalanceText>
            </h1>
          </div>
          <BigP className="body leader">
          
            <BalanceText>

          
              Read all about the latest gift ideas for pet and animal lovers.
              With top tips and Top 10 lists covering every occasion our blog is
              full of inspirational ideas and heartfelt stories for all…

            </BalanceText>
            
          </BigP>
        </div>
        <div className="spacer" />
        <div className="jpDivider bgBlue" />
      </Container>
      <Container>
        <div className="jpBlogContainer">
          <div className="jpBlogContent ">
            {BlogData.map((blog, index) => (
              <BlogItem
                key={index}
                title={blog.title}
                imageSrc={blog.imageSrc}
                imageAlt={blog.imageAlt}
                link={blog.link}
                description={blog.description}
              />
            ))}
          </div>
        </div>
      </Container>
      <ShippingFooter />
    </>
  );
};

export default BlogsPage;
