/* React */
import React from 'react';
import { usePhotobook } from '../../models';
import { getAuth } from 'firebase/auth';
import { app } from '../../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import PhotobookFrontPage from '../components/PhotobookFrontPage';
import PhotobookPages from '../components/PhotobookPages';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/system';
import Link from '@mui/material/Link';
import { useLocation } from 'react-router';
import { useLocale } from '../../api-locale';
import { CustomButton } from '../../styledComponents';

// Styled component for text
const Text = styled('div')`
  color: #85a2ad;
  margin: 0;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.03em;
  background-color: #FFF;
`;

const PhotobookInner = ({ user }) => {
  const location = useLocation();
  const [photobook] = usePhotobook(user.uid, user.uid);
  const { locale } = useLocale(user);

  if (!photobook || !locale) return null;

  return (
    <>
      <PhotobookFrontPage user={user} photobook={photobook} currency={locale.currencyCode} />
      {!user.isAnonymous && <PhotobookPages user={user} photobook={photobook} />}
      {user.isAnonymous && (
        <div className="bgWhite">
          <Text>To create a photobook please create an account.</Text>
          <div className="spacerMed" />
          <div className="checkoutFixedWidth">
          <Link 
            component={RouterLink}
            to={{ pathname: '/create-account', state: { redirect: location.pathname } }}
          >
            <CustomButton size="large" className="margin"> Create Account </CustomButton>
        </Link>
          </div>
          <div className="spacerMed" />
          <Text>Already have an account?</Text>
          <Link
            style={{ fontSize: '20px', letterSpacing: '0.6px', fontWeight: '500' }}
            component={RouterLink}
            to={{ pathname: '/sign-in', state: { redirect: location.pathname } }}
            underline="hover"
          >
            Sign In
          </Link>
        </div>
      )}
      <div className="spacer" />
    </>
  );
};

const Photobook = () => {
  const auth = getAuth(app);
  const [user] = useAuthState(auth);

  return user ? <PhotobookInner user={user} /> : null;
};

export default Photobook;
