// import React from "react";
import {withStyles} from "@mui/styles";
import Button from "@mui/material/Button";


export const TextButton = withStyles({
    root: {
    },
    label: {
        padding: "0!important",
        fontSize: "14px",
        color: "#413E40",
        "&:hover": {
            padding: 0,
            fontSize: "14px",
            color: "#413E40",
            background: "#FFF",
            textDecoration: "underline"
        }
    },
    // small
})(Button);
