/* React */
/*jshint esversion: 6 */
import React from "react";
import '@uppy/core/dist/style.css';
import '@uppy/drag-drop/dist/style.css';
import {makeStyles} from '@mui/styles';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import _ from "lodash";
import Grid from "@mui/material/Grid";
import {Link as RouterLink} from "react-router-dom";
import ProductPreview from "../components/ProductPreview";
import Dinero from "dinero.js";
import {Square} from "../../global/components/Square";
import {jigpawsDineroFormatted} from "../../utils";

const useStyles = makeStyles((theme) => ({
    category: {
        textAlign: "left",
        fontSize: "14px",
    },
    type: {
        textAlign: "left",
        display: "flex",
        alignItems: "center",
        height: "100%",
    },
    price: {
        textAlign: "left",
        fontWeight: "600",
        letterSpacing: "0.05em",
        display: "flex",
        alignItems: "center",
        height: "100%",
    },
    productContent: {
        background: "#85A2AD",
    },
    media: {
        width: "100%",
        height: "100%",
    },
}));

export const ProductCard = ({user, product, jigpaw, noDescription, currency}) => {
    const classes = useStyles();

    if (_.isNil(noDescription)) {
        noDescription = false;
    }

    console.log("ProductCard jigpaw", jigpaw);

    const pathname = `/product/${product.category.replace("_", "-")}/${jigpaw.linkUUID()}`;

    if (!currency) {
        return null;
    }

    const priceFormatted = jigpawsDineroFormatted(Dinero({amount: product.price, currency: currency}));

    return (
        <div className="productPreview">
            <Card elevation={0}>
                <CardActionArea component={RouterLink} to={{
                    pathname: pathname,
                    state: { variantId: product.id }
                }}>
                    <Square>
                        <CardMedia
                            className={classes.media}
                            component={ProductPreview}
                            user={user}
                            productPreview={product.preview}
                            jigpaw={jigpaw}
                            size={512}
                            style={{paddingBottom: "100%", display: "block", position: "relative"}}
                        />
                    </Square>
                    <CardContent className={classes.productContent}>
                        <div className="productInfoLarge">
                            <Grid container justifyContent="space-between">
                                <Grid item>
                                    <Typography variant="body2" component="div" className={classes.type}>
                                        {product.displayCategory}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    {!noDescription && (
                                        <Typography variant="body2" style={{flex: 1}} component="div" className={classes.price}>
                                            {priceFormatted}
                                        </Typography>
                                    )}
                                </Grid>
                            </Grid>
                        </div>
                        <div className="productInfoSmall">
                            <div>
                                <Typography variant="body2" component="div" className={classes.type}>
                                    {product.displayCategory}
                                </Typography>
                                {!noDescription && (
                                    <Typography variant="body2" component="div" className={classes.price}>
                                        {priceFormatted}
                                    </Typography>
                                )}
                            </div>
                        </div>
                        <Grid container justifyContent="space-between">
                            {!noDescription && (
                                <Typography variant="body1" component="div" className={classes.category}>
                                    {product.displayDetails}
                                </Typography>
                            )}
                        </Grid>
                    </CardContent>
                </CardActionArea>
            </Card>
        </div>
    );
};
