// ShippingInfo.js
import React from "react";
import Grid from "@mui/material/Grid";
import CenterGrid from "../CenterGrid.js";
import { useStyles_LandingPage as useStyles } from "../LandingPage/useStyles_LandingPage.js";
import { BigP } from "../styledComponents.js";

const ShippingInfo = ({ international }) => {
    const classes = useStyles();

    if (international === true) {
        return (
            <Grid item xs={12} md={6} className="bgGreen">
                <CenterGrid>
                    <Grid>
                        <div className="spacer desktopNone" />
                        <img
                            className={classes.appStore}
                            src="/images/svg/intShipping.svg"
                            alt="speeding delivery truck"
                            style={{ height: "100px" }}
                        />
                        <div className="spacerTiny" />
                        <div>
                            <h3>FREE INTERNATIONAL SHIPPING</h3>
                        </div>
                        <div className="spacerTiny" />
                        <div className="body">on jigsaws</div>
                        <div className="spacer desktopNone" />
                    </Grid>
                </CenterGrid>
            </Grid>
        );
    } else {
        return (
            <Grid item xs={12} md={6} className="bgGreen">
            <CenterGrid>
                <Grid>
                    <div className="spacer desktopNone"/>

                    <img className={classes.appStore} src="/images/svg/jpFreeShipping.svg"
                         alt="Speeding Truck"

                         style={{"maxWidth": "140px"}}/>

                    <div className="spacerSmall"/>
                    <div><h3>Free UK Shipping!</h3></div>
                    <div className="spacerSmall"/>
                    <BigP className="body">International and next day<br/>delivery also available</BigP>
                    <div className="spacer desktopNone"/>

                </Grid>
            </CenterGrid>
        </Grid>
        );
    }
};

export default ShippingInfo;
