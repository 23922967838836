//REACT
import React, {useState} from "react"

//FIREBASE
import { app } from "../../firebase";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { uploadRef } from "../../firebase";
import FirebaseCloudStorage from "../../FirebaseCloudStorage";
import {getFirestore, collection, query, where, orderBy, limit } from "firebase/firestore";

//MATERIALS
import { Grid } from "@mui/material";
import Card from '@mui/material/Card';

//INTERNAL IMPORTS
import JigpawType from "./JigPawType";
import {createJigpaw, jigpawConverter } from "../../models";

//EXTERNAL IMPORTS
import _ from "lodash";
import Uppy from '@uppy/core';
import {DragDrop, useUppy} from '@uppy/react';
import Compressor from '@uppy/compressor';

//COMPONENTS
import { Square } from "../../global/components/Square";
import { TextButton } from "../../global/components/TextButton";
import ConfirmationDialog from "../../global/components/ConfirmationDialog";

//HOOKS, UTILS, DATA
import useStyles from "./useStyles";





const JigpawImages = (props) => {
    const db = getFirestore(app);
    const classes = useStyles();
    const {user} = props;

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [deleteJigpaw, setDeleteJigpaw] = useState();
    const [deleteMode, setDeleteMode] = useState(false);
    const [toggleText, setToggleText] = useState("Delete images")

    const jigpawsQuery = query(
        collection(db, "jigpaws").withConverter(jigpawConverter),
        where("uid", "==", props.user.uid),
        where("deleted", "==", false),
        // where("stored.thumb", "==", true),
        orderBy("createdAt", "desc"),
        limit(100)
    );

    //todo
    // const [jigpaws, jigpawsLoading, JigpawsError] = useCollectionData(
    const [jigpaws ] = useCollectionData(    
        jigpawsQuery,
        {
            snapshotListenOptions: {includeMetadataChanges: false},
        }
    );

    const uppy = useUppy(() => {
        return new Uppy({
            autoProceed: true,
            restrictions: {
                maxFileSize: 10000000,
                allowedFileTypes: [
                    ".jpg",
                    ".jpeg",
                    ".png",
                    ".gif",
                    ".JPG",
                    ".JPEG",
                    ".PNG",
                    ".GIF"
                ]
            }
        })
        .use(FirebaseCloudStorage, {storageRef: uploadRef})
        .use(Compressor, { quality: 1 });;

    });

    uppy.on("upload-started", (file) => {
        // console.log("upload-started", file);
        if (user) {
            createJigpaw(user.uid, file.uuid);
        }

    });

    uppy.on("upload-success", (file, response) => {
        console.log("Upload success for file:", file.id, response);
        uppy.removeFile(file.id);  // Remove the file from Uppy after successful upload
    });

    const jigpawElements = _.map(jigpaws, (jigpaw) => (
        <Grid item xs={4} sm={4} md={3} lg={2} xl={1}>
                <JigpawType user={user} jigpaw={jigpaw} setDeleteDialogOpen={setDeleteDialogOpen} setDeleteJigpaw={setDeleteJigpaw} deleteMode={deleteMode}></JigpawType>
        </Grid>
    ))

    const deleteCancel = () => {
        setDeleteJigpaw(null)
        setDeleteDialogOpen(false)
    }

    const deleteConfirm = () => {
        deleteJigpaw.mark_deleted()
        setDeleteJigpaw(null)
        setDeleteDialogOpen(false)
    }

    const toggleDeleteMode = () => {
        setDeleteMode(!deleteMode);
        if (!!deleteMode) {
            setDeleteJigpaw(null)
            setDeleteDialogOpen(false)
            setToggleText("Delete images")
        } else {
            setToggleText("Done")
        }
    }

    return (
        <>

        <ConfirmationDialog title={"DELETE IMAGE"} open={deleteDialogOpen} handleCancel={deleteCancel} handleConfirm={deleteConfirm} cancelText={"CANCEL"} confirmText={"YES"} />
        <Grid container spacing={2} className={classes.imageGrid}>
            <Grid item xs={4} sm={4} md={3} lg={2} xl={1}>
                {/*<img src="/images/create/upload.jpg" height={"100%"} width={"100%"} />*/}
                <Square>
    <Card square elevation={0} variant="outlined" style={{ height: "100%", width: "100%" }} className={"fixDragDrop"}>
        <div style={{ height: "100%", width: "100%", position: "relative" }}>
            <DragDrop
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "white", 
                    padding: 0, 
                    margin: 0, 
                    color: "white",
                }}
                uppy={uppy}
                locale={{
                    strings: {
                        dropHereOr: '%{browse}',
                        browse: '&nbsp;',
                    },
                }}
            />
            <div style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                fontSize: "6rem",
                fontWeight: 100, 
                color: "#85A2AD",
                zIndex: 2,
                pointerEvents: "none"
            }}>+</div>
        </div>
    </Card>
</Square>






            </Grid>
            {jigpawElements}
        </Grid>
            <div>
                <TextButton variant="text" size="small" style={{color: "#413E40"}} onClick={toggleDeleteMode}>{toggleText}</TextButton>
            </div>
        </>
    );

}

export default JigpawImages