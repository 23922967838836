//REACT
import React, {useEffect} from 'react';
import {BrowserRouter, Route, Switch, useLocation} from "react-router-dom";

//FIREBASE

//TODOS


//Buttons: depth should be consistent throughout the site.
//Buttons: font point size should be consistent throughout the site.
//Prices: staging prices have not update/are not correct. Assume this will fix itself once live…?
//Gallery: ‘Create Now' button not displaying correctly. DONE
//Photobooks: Create Account button needs updating as above. DONE
//Follow Us: copy too small. Use current live site as ref. DONE
//Free International Shipping: copy too small. Use current live site as ref. DONE
//Product Size Selection: the selection bar should appear dark grey on hover. Use current live site as ref (see attached).
//Shipping Selection: dot not centred. Use current live site as ref (see attached). --NOT DONE
//Shipping: 'Checkout' button wrong size.  DONE
//Billing: Pay now button wrong size. DONE
//BILLING: Change head from ‘billing’ to ‘PAYMENT’ – change if you guys think it’s clearer…?

//MATERIALS
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider, StyledEngineProvider, adaptV4Theme } from '@mui/material/styles';


//INTERNAL IMPORTS
import ScrollToTop from "./ScrollToTop";
import DataLayerDebugger from './DataLayerDebugger';

//EXTERNAL IMPORTS
import {Redirect} from "react-router";
import {Helmet} from 'react-helmet';
import TagManager from 'react-gtm-module'

//COMPONENTS
import Footer from './Components/Footer/Footer';
import MyResponsiveGrid from "./JigpawsGrid";
// import JigpawAppBar from "./JigpawAppBar";
import JigpawAppBar from './Components/JigPawAppBar/JigPawAppBar';

import Deliveries from "./Deliveries";

//HOOKS AND UTILS

//ROUTES
import Home from "./Home";
import CreateAccount from "./CreateAccount";
import LandingPage from './LandingPage/LandingPage';
import GiftPage from "./GiftPage";


import blogPages from './blog/blogPages';

import BlogsPage from './blog/BlogIndex';
import ReactGA from 'react-ga';
import Create from "./create/pages/Create";
import SignIn from "./SignIn";
import Cart from "./shop/pages/Cart";
import ProductPage from "./products/pages/Products";
import ImageEditorPage from "./products/pages/ImageEditorPage";
import ProductsVariantsDetailPage from "./products/pages/ProductVariantsDetail"
import ShippingAndBilling from "./shop/pages/ShippingAndBilling";
import ForgotPassword from "./ForgotPassword";
import Newsletter from "./Newsletter";
import Photobook from "./photobook/pages/Photobook";
import FourOhFour from "./404";

//CSS
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './App.css';
import './global/static/Global.css';
// import { theme } from "./theme"



const letterSpacing = "0.1em";
const letterSpacingBody = "0.03em";

export const theme = createTheme(adaptV4Theme({
  typography: {
    fontFamily: [
      'Facundo',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontSize: 14,
  },
  palette: {
    primary: {
      main: '#85a2ad',
    },
    secondary: {
      main: '#413e40',
    },
    text: {
      primary: '#85a2ad',
    },
    background: {
      paper: '#FFF',
      default: '#FFF',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1400,
      xl: 1720,
    },
  },
  overrides: {
    MuiPaper: {
      outlined: {
        border: "1px solid #85a2ad",
      }
    },
    MuiList: {
      root: {
        color: "#FFF",
      }
    },
    MuiListItem: {
      button: {
        '&:hover': {
          backgroundColor: "#413E40",
          color: "#FFF"
        },
      }
    },
    MuiButton: {
      containedSizeLarge: {
        borderRadius: "0px",
        textTransform: "uppercase",
        color: "#FFF",
        boxShadow: "none",
        border: "2px solid #413E40",
        padding: "0",
        backgroundColor: "#413E40",
        '&:hover': {
          backgroundColor: "transparent",
          border: "2px solid #413E40",
          boxShadow: "none",
          color: "#413E40",
        },
      },
      outlinedSizeLarge: {
        padding: "0",
        border: "2px solid #FFF",
        color: "#FFF",
        borderRadius: "0px",
      },
      outlinedSizeSmall: {
        borderRadius: "0px",
        padding: "0",
        border: "2px solid #413E40",
        color: "#413E40",
        '&:hover': {
          backgroundColor: "#413E40",
          color: "#FFF"
        },
      },
      textSizeSmall: {
        padding: "0",
        fontSize: "14px",
      },
      outlined: {
        borderRadius: "0px",
        '&:hover': {
          backgroundColor: "#FFF",
          color: "#99BFC2"
        },
      },
      label: {
        fontWeight: "400",
        fontSize: "20px",
        lineHeight: "20px",
        letterSpacing: "0.05em",
      },
    },
  },
}));

theme.typography.h1 = {
  [theme.breakpoints.down('md')]: {},
  [theme.breakpoints.only('md')]: {},
};

theme.typography.h2 = {
  [theme.breakpoints.down('md')]: {},
};

theme.typography.h3 = {
  [theme.breakpoints.down('md')]: {},
};

theme.typography.h4 = {
  letterSpacing: letterSpacing,
  fontSize: '200px',
  fontWeight: 400,
  whiteSpace: 'pre-line',
  [theme.breakpoints.down('md')]: {
    fontSize: "3.45vw",
  },
  [theme.breakpoints.only('md')]: {
    fontSize: "3vw",
  },
};

theme.typography.h5 = {
  letterSpacing: letterSpacing,
  fontSize: '18px',
  fontWeight: 500,
  whiteSpace: 'pre-line',
  [theme.breakpoints.down('md')]: {
    fontSize: "3vw",
  },
  [theme.breakpoints.only('md')]: {
    fontSize: "2.1vw",
  },
};

theme.typography.h6 = {
  letterSpacing: letterSpacing,
  fontWeight: 500,
  fontSize: '20px',
  paddingLeft: 20,
  paddingRight: 20,
  [theme.breakpoints.up('md')]: {},
  [theme.breakpoints.down('md')]: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  [theme.breakpoints.only('xs')]: {
    fontSize: "2.8vw",
    paddingLeft: 5,
    paddingRight: 5,
  },
};

theme.typography.body1 = {
  letterSpacing: letterSpacingBody,
  fontWeight: 500,
  fontSize: '16px',
};

theme.typography.body2 = {
  letterSpacing: letterSpacingBody,
  fontWeight: 500,
  fontSize: '20px',
};

theme.typography.body2bold = {
  letterSpacing: "0.01",
  fontWeight: 600,
  fontSize: '20px',
};



//todo - bake this into build commands as env
const tagManagerArgs = {
    //LIVE
    gtmId: 'GTM-MF82ZVF'
    //Staging
    // gtmId: 'GTM-WK9VFFTV'
}
TagManager.initialize(tagManagerArgs)
//End tag manager

ReactGA.initialize('UA-187569997-1', {debug: false})


function LocationApp() {
    let location = useLocation();
    useEffect(() => {

        const newPath = location.pathname.replace(/\/$/, '');
        // Redirect to the new path without trailing slash
        if (location.pathname !== newPath) {
            window.history.replaceState({}, '', newPath);
            return //don't log?
        }

        window.dataLayer.push({
            // event: 'Page View',
            event: 'page-view',
            page: {
                url: location.pathname,
                // title: document.title
            }
        });
    }, [location])

    const href = window.location.href.split("#")[0].split("?")[0]
    return <Helmet>
        <link rel="canonical" href={href}/>
    </Helmet>
}

function App() {

    return (
      
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <div className="App">
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>Jigpaws – Personalised pet photo gifts | Free shipping</title>
                    <meta name="description"
                          content="Create and share personalised pet photo gift with friends and family. Jigpaws. Share a little piece of the family."/>
                    <link rel="canonical" href="https://www.jigpaws.com/"/>
                    <meta property="og:url"
                          content="https://www.jigpaws.com/"/>
                    <meta property="og:type" content="article"/>
                    <meta property="og:title" content="Jigpaws – Home"/>
                    <meta property="og:description"
                          content="Create a personalised pet photo gift with Jigpaws today"/>
                    <meta property="og:image" content="https://www.jigpaws.com/images/social-logo.png"/>
                </Helmet>
                <BrowserRouter>
                {/* <DataLayerDebugger /> */}
                    <JigpawAppBar />

                    <ScrollToTop>
                        <Switch>
                            <Route path="/create-account" component={CreateAccount} />
                            <Route path="/sign-in" component={SignIn} />
                            <Route path="/forgotten-password" component={ForgotPassword} />
                            <Route path="/product/:productId/:jigpawId/image-editor" component={ImageEditorPage} />
                            
                            <Route path="/product/photobook" component={Photobook} />
                            <Route path="/product/photobook/:frontPageJigpawId" component={Photobook} />
                            <Route path="/product/:productId/:jigpawId" component={ProductsVariantsDetailPage} />
                            <Route path="/product/:productId" component={ProductsVariantsDetailPage} />
                            <Route path="/products/:jigpawId" component={ProductPage} />
                            <Route path="/create"> <Redirect to="/create-personalised-pet-photo-gifts" /></Route>
                            <Route path="/create-personalised-pet-photo-gifts" component={Create} />
                            <Route path="/products"> <Redirect to="/products-personalised-gifts" /></Route>
                            <Route path="/gifts/:petType" component={GiftPage} />
                            <Route path="/gift-products/:productType" component={LandingPage} />
                            {blogPages.map((blog, index) => (
                              <Route exact key={index} path={blog.path} component={blog.component} />
                            ))}
                            <Route path="/blog/" component={BlogsPage} />
                            <Route path="/gallery"> <Redirect to="/gallery-pet-photos" /></Route>
                            <Route path="/gallery-pet-photos" component={MyResponsiveGrid} />
                            <Route path="/deliveries-and-returns" component={Deliveries} />
                            <Route path="/shop/cart" component={Cart} />
                            <Route path="/shop/billing-and-shipping" component={ShippingAndBilling} />
                            <Route path="/newsletter/" component={Newsletter} />
                            <Route path="/404/" component={FourOhFour} />
                            <Route path="/" component={Home} />
                            <Redirect from="/:url*(/+)" to={window.location.pathname.slice(0, -1)} />
                        </Switch>
                    </ScrollToTop>
                    <LocationApp />
                </BrowserRouter>
            </div>
            <Footer />
        </ThemeProvider>
      </StyledEngineProvider>
    );
}

export default App;
