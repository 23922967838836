// HOOKS, UTILS, DATA
import { useStyles } from "./useStyles";

 const Margin = ({style, index, background}) => {
    const classes = useStyles();

    return (
        <div style={style} className={classes.mobileFix}>
            <div style={{
                position: 'absolute',
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
                // background, background
            }}>
                {/*{index}*/}
            </div>
        </div>
    )
}

export default Margin;