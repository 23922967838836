//REACT
import React from "react"
import {Link as RouterLink} from "react-router-dom";

//MATERIALS
import { Card } from "@mui/material";
import CardActionArea from '@mui/material/CardActionArea';
import CardMedia from '@mui/material/CardMedia';

//EXTERNAL IMPORTS
import _ from "lodash";


//HOOKS AND UTILS
import useStyles from "./useStyles";
import { useMainImage } from "../../storageUtils";

//COMPONENTS
import { Square } from "../../global/components/Square";


export default function JigpawCard({user, jigpaw, setDeleteDialogOpen, setDeleteJigpaw, deleteMode} ) {
    const oid = _.get(jigpaw, "oid", jigpaw.uid);
    const {url} = useMainImage(user, oid, jigpaw.uuid, jigpaw);
    const classes = useStyles();


    const openDeleteDialog = () => {
        setDeleteJigpaw(jigpaw)
        setDeleteDialogOpen(true)
    };

    if (url && deleteMode) {
        return (
            <Card square elevation={0} variant="outlined" className={classes.deleteCard}>
                <Square>
                <CardActionArea onClick={openDeleteDialog}>
                    <CardMedia
                        component="img"
                        alt="Jigpaw imageA"
                        image={url}
                        title="Jigpaw"
                    />
                    <div className={classes.deleteBackdrop}></div>
                    <img className={classes.deleteOverlay} src={"/images/svg/del.svg"} alt="delete"/>
                </CardActionArea>
                </Square>
            </Card>
        );
    }

    if (url && !deleteMode) {
        return (
            <Card square elevation={0} variant="outlined" onClick={() => console.log("clicked")}>
                <Square>
                <CardActionArea component={RouterLink} to={{
                    pathname: `/products/${jigpaw.linkUUID()}`
                }}>
                    <CardMedia
                        component="img"
                        alt="Jigpaw imageB"
                        image={url}
                        title="Jigpaw"
                    />
                </CardActionArea>
                </Square>
            </Card>
        );
    }
    return (null);
}