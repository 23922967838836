const catSlider = [
    {
        src: "/images/Homepage2023/JP_Home_Cat_3.jpg",
        description: 'A cat jigsaw from Jigpaws',
    },
    {
        src: "/images/Homepage2023/JP_Home_Cat_4.jpg",
        description: 'A cat cushion from Jigpaws',
    },
    {
        src: "/images/Homepage2023/JP_Home_Cat_2.jpg",
        description: 'A cat photo book from Jigpaws',
    },
    {
        src: "/images/Homepage2023/JP_Home_Cat_5.jpg",
        description: 'A cat photo tile from Jigpaws',
    },
    {
        src: "/images/Homepage2023/JP_Home_Cat_1.jpg",
        description: 'A cat journal from Jigpaws',
    },
    {
        src: "/images/Homepage2023/JP_Home_Cat_6.jpg",
        description: 'A cat card from Jigpaws',
    }
];
const dogSlider = [
    {
        src: "/images/Homepage2023/JP_Home_Dog_1.jpg",
        description: 'A dog jigsaw from Jigpaws',
    },
    {
        src: "/images/Homepage2023/JP_Home_Dog_2.jpg",
        description: 'A dog cushion from Jigpaws',
    },
    {
        src: "/images/Homepage2023/JP_Home_Dog_3.jpg",
        description: 'A dog photo book from Jigpaws',
    },
    {
        src: "/images/Homepage2023/JP_Home_Dog_4.jpg",
        description: 'A dog photo tile from Jigpaws',
    },
    {
        src: "/images/Homepage2023/JP_Home_Dog_5.jpg",
        description: 'A dog photo journal from Jigpaws',
    },
    {
        src: "/images/Homepage2023/JP_Home_Dog_6.jpg",
        description: 'A dog card from Jigpaws',
    }
];
const heroSlider = [
    {
        src: "/images/Homepage2023/JP_Home_Hero_1.jpg",
        description: 'A cat and a cat jigsaw from Jigpaws',
    },
    {
        src: "/images/Homepage2023/JP_Home_Hero_2.jpg",
        description: 'A dog and a dog jigsaw from Jigpaws',
    },
    {
        src: "/images/Homepage2023/JP_Home_Hero_3.jpg",
        description: 'A cat and a cat jigsaw from Jigpaws',
    },
    {
        src: "/images/Homepage2023/JP_Home_Hero_4.jpg",
        description: 'A cat and a dog jigsaw from Jigpaws',
    },
    {
        src: "/images/Homepage2023/JP_Home_Hero_5.jpg",
        description: 'A cat jigsaw from Jigpaws',
    }
];
const otherSlider = [
    {
        src: "/images/Homepage2023/JP_Home_Other_1.jpg",
        description: 'A horse jigsaw from Jigpaws',
    },
    {
        src: "/images/Homepage2023/JP_Home_Other_3.jpg",
        description: 'A tortoise photo tile from Jigpaws',
    },
    {
        src: "/images/Homepage2023/JP_Home_Other_2.jpg",
        description: 'A fish photo book from Jigpaws',
    },
    {
        src: "/images/Homepage2023/JP_Home_Other_4.jpg",
        description: 'A bird journal from Jigpaws',
    },
    {
        src: "/images/Homepage2023/JP_Home_Other_5.jpg",
        description: 'A squirrel card from Jigpaws',
    }
];
const landingSlider = [
    {
        src: "/images/landingpages/cards/cat1.webp",
        description: 'A horse jigsaw from Jigpaws',
    },
    {
        src: "/images/landingpages/notebooks/dog1.webp",
        description: 'A tortoise photo tile from Jigpaws',
    },
    {
        src: "/images/landingpages/photo-tiles/pet2.webp",
        description: 'A fish photo book from Jigpaws',
    },
    {
        src: "/images/landingpages/cushions/generic4.webp",
        description: 'A bird journal from Jigpaws',
    },
     {
        src: "/images/landingpages/photo-books/cat1.webp",
        description: 'A bird journal from Jigpaws',
    },
    {
        src: "/images/landingpages/jigsaws/generic4.webp",
        description: 'A squirrel card from Jigpaws',
    }
];
const home_slider_settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dotColor: "white"

};

export { catSlider, dogSlider, heroSlider, otherSlider,landingSlider, home_slider_settings };