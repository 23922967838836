//REACT
import React from "react";

//FIREBASE
//MATERIALS
//INTERNAL IMPORTS

//EXTERNAL IMPORTS
import _ from "lodash"
import Dinero from "dinero.js";

//COMPONENTS

//HOOKS, UTILS, DATA
import { jigpawsDineroFormatted } from "./utils";
import { useLocale } from "./api-locale";
import { useProducts } from "./API";

const ProductPrice = ({ user, productId, startingFrom = false, each = false }) => {
    if (!user) return null;
    return <ProductPriceCurrency user={user} productId={productId} startingFrom={startingFrom} each={each} />;
  };
  
  const ProductPriceCurrency = ({ user, productId, startingFrom, each }) => {
    const { locale } = useLocale(user);
    if (!locale) return null;
    return <ProductPriceFinal productId={productId} currency={locale.currencyCode} startingFrom={startingFrom} each={each} />;
  };
  
  const ProductPriceFinal = ({ productId, currency, startingFrom, each = false }) => {
    const [productResponse] = useProducts("all", currency);
    if (!productResponse) return null;
  
    const product = _.find(productResponse.products, { id: productId }); 
    if (!product) return null;
  
    const priceFormatted = jigpawsDineroFormatted(Dinero({ amount: product.price, currency }));
    return (
      <div className="jpProductCost">
        {startingFrom && "from – "}
        <span className="bold" sx={{ fontWeight: 600, fontSize: "24px" }}>
  {priceFormatted}
</span> {each && "each"}
      </div>
    );
  };

  export default ProductPrice;