import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Link from "@mui/material/Link";
import {sendPasswordResetEmail, signOut} from "firebase/auth";
import { auth } from "./firebase";
import {useAuthState} from "react-firebase-hooks/auth";
import {Link as RouterLink} from "react-router-dom";
import {useFormStyles, useQuery} from "./utils";
import _ from "lodash";
import Container from "@mui/material/Container";


let signingOut = false;

const ForgotPassword = () => {
    const formClasses = useFormStyles();
    const [user, loading, error] = useAuthState(auth);

    const query = useQuery();
    const initialEmail = query.get("email");
    const [email, setEmail] = useState(initialEmail);
    const [handlingSubmit, setHandlingSubmit] = useState(false);
    const [message, setMessage] = useState();
    const [sent, setSent] = useState(false);
    const [errors, setErrors] = useState({});
    const [errorCode, setErrorCode] = useState();
    const [errorMessage, setErrorMessage] = useState();

    const handleSubmit = e => {
        setHandlingSubmit(true);
        setErrors({});
        setErrorMessage(null);
        setErrorCode(null);
        e.preventDefault();

        if (user) {
            //START spinner

            const signInUrl = process.env.REACT_APP_SIGN_IN_URL

            const actionCodeSettings = {
                // After password reset, the user will be give the ability to go back
                // to this page.
                url: `$(REACT_APP_SIGN_IN_URL)?email=email`,
                handleCodeInApp: false
            };
            sendPasswordResetEmail(auth, email)
                .then(() => {
                    setMessage(`Password reset email sent to ${email}.`);
                    setSent(true);
                })
                .catch((error) => {
                    //  console.log("password reset failed...", error);
                    // https://firebase.google.com/docs/auth/admin/errors

                    setErrorCode(error.code);

                    switch (error.code) {
                        case 'auth/user-not-found':
                            setErrors({'email': 'Account not found...'})
                            break;
                        default:
                            setErrorMessage(error.message);
                    }
                }).finally(() => {
                setHandlingSubmit(false);
            });
        }
    };

    const emailOnChange = e => {
        e.preventDefault();
        setErrors(_.omit(error, "email"));
        setEmail(e.target.value);
    };

    const handleSignOut = e => {
        e.preventDefault();
        if (!signingOut) {
            signingOut = true;
            signOut(auth).then(() => {
                // console.log("signed out... from sign in page");
            }).catch((error) => {
                // console.log("failed sign out... from sign in page");
            }).finally(() => {
                // console.log("signing out finalllly");
                signingOut = false;
            });
        }
    };

    const handleRetry = e => {
        e.preventDefault();
        setMessage(null);
        setSent(false);
    };

    if (loading) {
        return (null);
    }
    if (error) {
        return (null);
    }
    if (user && !user.isAnonymous) {
        return <div>Logged in as {user.displayName} ({user.email}) <Link onClick={handleSignOut} underline="hover">Sign out</Link></div>;
    }

    const signInUrl = (email && email.length > 3) ? `/sign-in?email=${email}` : `/sign-in`;
    const createUrl = (email && email.length > 3) ? `/create-account?email=${email}` : `/create-account`;

    if (message) {
        return <>
            <div>{message}</div>
            { sent &&
                <Link component={RouterLink} to={signInUrl} underline="hover">Sign In</Link>
            }
            <div><Link onClick={handleRetry} underline="hover">Try again</Link></div>
    </>;
    }

    return <>
        <div className="spacer"/>
        <div className="pageTitle"><h1>Reset Password</h1></div>
        <div className="spacer"/>
        <Container disableGutters={true} className={formClasses.container}>
            <div className="formBox">
                <form className={formClasses.root} onSubmit={handleSubmit}>
                    <TextField
                        label="Email"
                        variant="filled"
                        type="email"
                        required
                        value={email}
                        onChange={emailOnChange}
                        error={errors.email}
                        helperText={errors.email}
                    />
                    <Button type="submit" variant="contained" size="large"
                            disabled={handlingSubmit || !_.isEmpty(errors)}>
                        Reset password
                    </Button>
                    <div><Link to={signInUrl} component={RouterLink} underline="hover">Sign In</Link></div>
                    <div><Link to={createUrl} component={RouterLink} underline="hover">Create account</Link></div>
                </form>
            </div>
        </Container>
        <div className="spacer"/>
    </>;
}

export default ForgotPassword;
