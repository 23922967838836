import React from "react"

const productData = {
    //SECTION PHOTO-TILES
    'pet-photo-tiles': {
        'consistent':{
            'createLink': '/product/photo-tile',
            'price': 'photo-tile',
            'priceFrom': false,
        },
        'head': {
            'title': 'Personalised Pet Photo-tiles | Create | Jigpaws'
        }, 'meta': {
            'description': 'Create your own personalised pet photo tiles easily at Jigpaws by using our dedicated online tool. Pick your pet picture and upload it on a photo tile, instantly!',
            'og_url': 'https://www.jigpaws.com/gift-products/pet-photo-tiles',
            'og_title': 'Create Your Pet Photo Tile | Order Online | Jigpaws',
            'og_description': 'Create your own personalised pet photo tiles easily at Jigpaws by using our dedicated online tool. Pick your pet picture and upload it on a photo tile, instantly!',
            'og_image': 'https://www.jigpaws.com/images/Jigpaws-social-2.jpg',
        }, 'header': {
            'title': 'Create Personalised Pet Photo Tiles with Jigpaws',
            'description': 'Welcome to Jigpaws, where making your own, fully personalised pet picture frames has never been easier!',
            // 'description2': 'Once you have completed your fully customisable and personalised ' + 'cat gifts, you can ship them absolutely free to anywhere in the UK!'
        }, 'panel_1': {
            'title': 'Create Your Personalised Pet Photo and Frame Here',
            'description': 'Creating a stunning piece of art dedicated to your favourite pet has never been easier than with Jigpaws. See your image come to life on a 20 x 20 cm personalised pet photo frame, printed with vivid colour and in high detail. Perfect as a gift for friends, family, or a way to commemorate a beloved companion. \n' +
                '\n' +
                'Our removable pet photo tiles use a specialist adhesive that allows for repositioning and resticking up to five times. Designed for painted walls and a variety of other surfaces, each custom pet photo tile can be easily hung without tools and without the risk of residue.\n' +
                '\n' +
                "All personalised photo tiles come with a plastic frame made from up to 50% recycled materials, beautifully framing your pet's picture.",
            'image': {
                'src': '/images/landingPages/photo-tiles/pet1.jpg',
                'alt': 'Jigpaws personalised gift photo tile with pet photo'
            }
        }, 'panel_2': {
            'title': 'Add Your Image',
            'description': 'Simply upload your favourite pet image to see your brand-new, highly detailed, fully personalised pet photo tile spring to life!.',
            'description2': 'Or choose an existing photo in our editing tool and see your new personalised pet picture frame come alive. Your image is printed onto a wooden insert in full detail and vibrant colour. ',
            'image': {
                'src': '/images/landingPages/uploadPhototile.jpg',
                'alt': 'Upload your image with Jigpaws',
            }
        }, 'panel_3': {
            'title': 'Add a Style',
            'description': 'Easily flip and reposition your pet picture until your pet stands out on your brand-new personalised pet photo tile. \n' +
                '\n' +
                'For best results, take your image from a good phone, tablet or digital camera. Ensure that your favourite pet is at the centre of the image and well-lit. Creating a fully personalised pet photo tile has never been easier than with us at Jigpaws. With endless possibilities, what are you waiting for? \n',
            'image': {
                'src': '/images/landingPages/photo-tiles/pet2.jpg',
                'alt': 'Stylise your pet photo-tile'
            }
        }, 'panel_4': {
            'title': 'Order Your New Pet Photo Tile To Your Door',
            'description': 'With free shipping available to the mainland UK, Jigpaws can have your new and fully personalised pet photo tile with you within 2 - 6 working days. International shipping is also available. ',
            // 'description2': '• 30 x 30cm\n • 46 x 46cm\n',
            // 'list': '• 30 x 30cm\n • 46 x 46cm\n',
            'image': {
                'src': '/images/landingPages/photo-tiles/pet3.jpg',
                'alt': 'Order your pet-phototile'
            }
        },
    },
    'dog-photo-tiles': {
        'consistent':{
            'createLink': '/product/photo-tile',
            'price': 'photo-tile',
            'priceFrom': false,
        },
        'head': {
            'title': 'Create Your Dog Photo Tile | Order Online | Jigpaws'
        }, 'meta': {
            'description': 'Create your own personalised dog photo tiles quickly at Jigpaws by using our dedicated online tool. Pick your dog picture and upload it on a photo tile, today\n',
            'og_url': 'https://www.jigpaws.com/gift-products/dog-photo-tiles',
            'og_title': 'Create Your Pet Photo Tile | Order Online | Jigpaws',
            'og_description': 'Create your own personalised dog photo tiles quickly at Jigpaws by using our dedicated online tool. Pick your dog picture and upload it on a photo tile, today\n',
            'og_image': 'https://www.jigpaws.com/images/Jigpaws-social-2.jpg',
        }, 'header': {
            'title': 'Create Personalised Dog Photo Tiles with Jigpaws',
            'description': 'Welcome to Jigpaws, where making your own, fully personalised dog picture frames has never been easier!',
            // 'description2': 'Once you have completed your fully customisable and personalised ' + 'cat gifts, you can ship them absolutely free to anywhere in the UK!'
        }, 'panel_1': {
            'title': 'Create Your Personalised Dog Photo Tiles and Frame Here\n',
            'description': 'Creating a stunning piece of art dedicated to your loyal companion has never been easier than with Jigpaws. Your personalised dog photo is printed in high detail and vivid colour. Perfect as a gift for family, or friends or as a way to commemorate a beloved family pet.\n' +
                // '\n' +
                // 'Simply upload a favourite image of your dog and see it come to life on a 20 x 20 cm personalised dog photo tile with a black recycled plastic frame. The high-detail and vivid colour print makes it a perfect gift for family and friends. \n' +
                '\n' +
                "These handmade photo tiles come with an adhesive that allows effortless repositioning on painted walls without the need for tools. All personalised photo tiles come with a black recycled plastic frame displaying your dog's picture.",
            'image': {
                'src': '/images/landingPages/photo-tiles/dog1.jpg',
                'alt': 'Jigpaws personalised gift photo tile with dog photo'
            }
        }, 'panel_2': {
            'title': 'Add Personalised Dog Photo',
            'description': 'Simply upload a favourite image of your dog and see it come to life on a 20 x 20 cm personalised dog photo tile with a black recycled plastic frame. The high-detail and vivid colour print makes it a perfect gift for family and friends.',
            'description2': 'Or choose an existing photo in our editing tool and see your new personalised pet picture frame come alive. Your image is printed onto a wooden insert in full detail, using vibrant colour.',
            'image': {
                'src': '/images/landingPages/uploadPhototile.jpg',
                'alt': 'Upload your image with Jigpaws',
            }
        }, 'panel_3': {
            'title': 'Add a Style to Your Framed Dog Picture',
            'description': 'Easily flip and reposition your personalised dog photo until your dog stands out and looks its best on your brand-new framed dog picture!\n ' +
                                '\n' +
                'For best results, take your image from a good phone, tablet or digital camera. Ensure that the dog is at the centre of the image and under good lighting. \n' +
                                '\n' +
                'Creating a fully framed dog picture tile has never been easier than with us at Jigpaws. With endless customisation options and filters, what are you waiting for?',
            'image': {
                'src': '/images/landingPages/photo-tiles/dog2.jpg',
                'alt': 'Stylise your dog photo tile with Jigpaws'
            }
        }, 'panel_4': {
            'title': 'Order Your New Dog Photo Tile To Your Door',
            'description': 'With free shipping available to the mainland UK, Jigpaws can have your new and fully personalised pet photo tile with you within 2 - 6 working days. International shipping is also available. ',
            // 'description2': '• 30 x 30cm\n • 46 x 46cm\n',
            // 'list': '• 30 x 30cm\n • 46 x 46cm\n',
            'image': {
                'src': '/images/landingPages/photo-tiles/dog3.jpg',
                'alt': 'Jigpaws personalised gift photo tile with dog picture'
            }
        },
    },
    'cat-photo-tiles': {
        'consistent':{
            'createLink': '/product/photo-tile',
            'price': 'photo-tile',
            'priceFrom': false,
        },
        'head': {
            'title': 'Create Your Cat Photo Tiles | Order Online | Jigpaws'
        }, 'meta': {
            'description': 'Create your framed cat picture tiles easily at Jigpaws by using our dedicated online tool. Pick your favourite cat picture and upload it on a photo tile, instantly!',
            'og_url': 'https://www.jigpaws.com/gift-products/dog-photo-tiles',
            'og_title': 'Create Your Pet Photo Tile | Order Online | Jigpaws',
            'og_description': 'Create your framed cat picture tiles easily at Jigpaws by using our dedicated online tool. Pick your favourite cat picture and upload it on a photo tile, instantly!',
            'og_image': 'https://www.jigpaws.com/images/Jigpaws-social-2.jpg',
        }, 'header': {
            'title': 'Create Personalised Cat Photo Tiles with Jigpaws',
            'description': 'Welcome to Jigpaws, where making your own, fully personalised cat photo tiles has never been easier!',
            // 'description2': 'Once you have completed your fully customisable and personalised ' + 'cat gifts, you can ship them absolutely free to anywhere in the UK!'
        }, 'panel_1': {
            'title': 'Create Custom Framed Cat Pictures With Jigpaws',
            'description': 'Creating a stunning piece dedicated to your favourite cat has never been easier than with us at Jigpaws. See your cat image come to life on a 20 x 20 cm personalised cat photo tile, printed with vivid colour and in high detail. Perfect as a gift for friends, or family or as a way to cherish or remember a beloved companion.\n'+
                '\n'+
'Jigpaws’ fully removable cat photo tiles use a special adhesive that allows for repositioning and resticking up to five times on painted walls and other surfaces.',
            'image': {
                'src': '/images/landingPages/photo-tiles/cat1.jpg',
                'alt': 'Jigpaws personalised gift photo tile with cat photo'
            }
        }, 'panel_2': {
            'title': 'Add an Image to Your Framed Cat Picture',
            'description': 'Simply upload your favourite cat image to see your new highly detailed and fully personalised framed cat picture spring to life! Each cat photo tile is handmade with a black square frame made of 50% recycled plastic.',
            'description2': 'Or choose an existing photo using our dedicated editing tool and see your new framed cat picture come to life! Your image is printed onto a wooden insert in full detail and vibrant colour.',
            'image': {
                'src': '/images/landingPages/uploadPhototile.jpg',
                'alt': 'Upload your image with Jigpaws',
            }
        }, 'panel_3': {
            'title': 'Pick Your Style',
            'description': 'Easily flip and reposition your picture until your cat stands out on your brand-new personalised cat photo tile.\n ' +
                                '\n' +
                'For best results, ensure that your image was taken from a good phone, tablet or digital camera. Position your cat to the centre of the photo tile and in good lighting for best results. Creating a fully personalised cat photo tile has never been easier than with us at Jigpaws. With endless creative possibilities, what are you waiting for?',
            'image': {
                'src': '/images/landingPages/photo-tiles/cat2.jpg',
                'alt': 'Stylise your image with Jigpaws'
            }
        }, 'panel_4': {
            'title': 'Order Your Cat Photo Tile To Your Door',
            'description': 'With free shipping available to the mainland UK, Jigpaws can have your new and fully personalised pet photo tile with you within 2 - 6 working days. International shipping is also available. ',
            // 'description2': '• 30 x 30cm\n • 46 x 46cm\n',
            // 'list': '• 30 x 30cm\n • 46 x 46cm\n',
            'image': {
                'src': '/images/landingPages/photo-tiles/cat3.jpg',
                'alt': 'Jigpaws personalised gift photo tile with cat photo'
            }
        },
    },
    // SECTION PHOTOBOOKS
    'pet-photo-books': {
        'consistent':{
            'createLink': '/product/photobook/',
            'price': 'photobook-14x14',
            'priceFrom': false,
        },
        'head': {
            'title': 'Create Your Pet Photo Album | Order Online | Jigpaws'
        }, 'meta': {
            'description': 'Create customisable pet photo books instantly with us at Jigpaws. Add a new piece to your home with free delivery available and with hundreds of items to choose from.',
            'og_url': 'https://www.jigpaws.com/gift-products/pet-photo-books',
            'og_title': 'Create Your Pet Photo Album | Order Online | Jigpaws',
            'og_description': 'Create customisable pet photo books instantly with us at Jigpaws. Add a new piece to your home with free delivery available and with hundreds of items to choose from,',
            'og_image': 'https://www.jigpaws.com/images/Jigpaws-social-2.jpg',
        }, 'header': {
            'title': 'Create Personalised Pet Photo Albums with Jigpaws',
            'description': 'Welcome to Jigpaws, where making your own, fully customisable pet photo book has never been simpler!',
            // 'description2': 'Once you have completed your fully customisable and personalised ' + 'cat gifts, you can ship them absolutely free to anywhere in the UK!'
        }, 'panel_1': {
            'title': 'Create Your Pet Photo Album',
            'description': "Are you looking for a unique way to cherish your beloved pet? Or, are you looking for a way to honour your friend's pets for a special occasion? Now you can create fully personalised pet photo book albums with Jigpaws. \n" +
            '\n'+
            'Simply upload 20 images plus your favourite cover image to see your pet printed in high detail and with vibrant colours.',
            'image': {
                'src': '/images/landingPages/photo-books/pet1.jpg',
                'alt': 'Jigpaws personalised gift photobook with dog photo'
            }
        }, 'panel_2': {
            'title': 'Add Up To 20 Images\n',
            'description': 'Printed on a thick, 2.5mm hardcover, our pet photo albums are perfect for remembering a beloved pet or for showing off your most iconic pet pal! Our library-bound photo album books are perfect for a gift for any occasion \n' +
                '\n' +
                'Add style and flair with a range of filters, effects and much more to your personalised photo album, with our simple editing tool. Your images will then be printed on 170gsm satin-finished, sustainably sourced paper.',
            // 'description2': 'Choose an existing photo in our editing tool and see your new personalised pet picture frame come alive. Your image is printed onto a wooden insert in full detail and vibrant colour. ',
            'image': {
                'src': '/images/landingPages/uploadphotobook.jpg',
                'alt': 'Upload your image with Jigpaws',
            }
        }, 'panel_3': {
            'title': 'Pick Your Size',
            'description': 'Our library-bound pet photo albums are available in 2 sizes:',
            'list': '• 14x14cm\n' +
                '• 21x21cm\n',
            'image': {
                'src': '/images/landingPages/photo-books/pet2.jpg',
                'alt': 'Choose your photobook with Jigpaws'
            }
        }, 'panel_4': {
            'title': 'Order Your Pet Photo Album To Your Door',
            'description': 'With free shipping available to mainland UK, Jigpaws can have your new, personalised pet photo book with you within 3 - 7 working days. International shipping is also available.',
            // 'description2': '• 30 x 30cm\n • 46 x 46cm\n',
            // 'list': '• 30 x 30cm\n • 46 x 46cm\n',
            'image': {
                'src': '/images/landingPages/photo-books/pet3.jpg',
                'alt': 'Jigpaws personalised gift photbook with pet photo'
            }
        },
    },
    'dog-photo-books': {
        'consistent':{
            'createLink': '/product/photobook/',
            'price': 'photobook-14x14',
            'priceFrom': false,
        },
        'head': {
            'title': 'Create Your Dog Photo Album | Order Online | Jigpaws'
        }, 'meta': {
            'description': 'Create customisable dog photo books instantly with us at Jigpaws. Add a new piece to your home with free delivery available and with hundreds of items to choose from.',
            'og_url': 'https://www.jigpaws.com/gift-products/dog-photo-books',
            'og_title': 'Create Your Pet Photo Album | Order Online | Jigpaws',
            'og_description': 'Create customisable dog photo books instantly with us at Jigpaws. Add a new piece to your home with free delivery available and with hundreds of items to choose from.',
            'og_image': 'https://www.jigpaws.com/images/Jigpaws-social-2.jpg',
        }, 'header': {
            'title': 'Create Personalised Dog Photo Albums with Jigpaws',
            'description': 'Welcome to Jigpaws, where making your own, fully customisable dog photo album has never been simpler!',
            // 'description2': 'Once you have completed your fully customisable and personalised ' + 'cat gifts, you can ship them absolutely free to anywhere in the UK!'
        }, 'panel_1': {
            'title': 'Create Your Dog Photo Album',
            'description': "Are you looking for a unique way to celebrate your or a loved one’s perfect pooch? Now you can create fully personalised dog photo albums or photo books with us here at Jigpaws.\n" +
            '\n'+
            'Simply upload 20 images plus your favourite cover image to see your dog printed in high detail and with vibrant colours.',
            'image': {
                'src': '/images/landingPages/photo-books/dog1.jpg',
                'alt': 'Jigpaws personalised gift photobook with dog photo'
            }
        }, 'panel_2': {
            'title': 'Add 20 Unique Images',
            'description': 'Printed on a thick, 2.5mm hardcover, our dog photo books are perfect for remembering a beloved dog or for showing off your most iconic pet pal! Our library-bound dog photo album books are perfect for a gift for any occasion. \n' +
                '\n' +
                'Add style and flair with a range of filters, effects and much more to your new photo book, with our simple editing tool. Your images will then be printed on 170gsm satin-finished, sustainably sourced paper.',
            // 'description2': 'Choose an existing photo in our editing tool and see your new personalised pet picture frame come alive. Your image is printed onto a wooden insert in full detail and vibrant colour. ',
            'image': {
                'src': '/images/landingPages/uploadphotobook.jpg',
                'alt': 'Upload your image with Jigpaws',
            }
        }, 'panel_3': {
            'title': 'Pick Your Size',
            'description': 'Our library-bound dog photo albums are available in 2 sizes:',
            'list': '• 14x14cm\n' +
                '• 21x21cm\n',
            'image': {
                'src': '/images/landingPages/photo-books/dog2.jpg',
                'alt': 'Choose your photobook with Jigpaws'
            }
        }, 'panel_4': {
            'title': 'Order Your Dog Photo Album To Your Door',
            'description': 'With free shipping available to mainland UK, Jigpaws can have your new, personalised dog photo book with you within 3 - 7 working days.',
            // 'description2': '• 30 x 30cm\n • 46 x 46cm\n',
            // 'list': '• 30 x 30cm\n • 46 x 46cm\n',
            'image': {
                'src': '/images/landingPages/photo-books/dog3.jpg',
                'alt': 'Jigpaws personalised photo-book with dog photo'
            }
        },
    },
    'cat-photo-books': {
        'consistent':{
            'createLink': '/product/photobook/',
            'price': 'photobook-14x14',
            'priceFrom': false,
        },
        'head': {
            'title': 'Create Your Cat Photo Album | Order Online | Jigpaws'
        }, 'meta': {
            'description': 'Create personalised cat photo books instantly with Jigpaws. Add to your kitty collection with free delivery available and many different items to choose from.',
            'og_url': 'https://www.jigpaws.com/gift-products/cat-photo-books',
            'og_title': 'Create Your Pet Photo Album | Order Online | Jigpaws',
            'og_description': 'Create personalised cat photo books instantly with Jigpaws. Add to your kitty collection with free delivery available and many different items to choose from.',
            'og_image': 'https://www.jigpaws.com/images/Jigpaws-social-2.jpg',
        }, 'header': {
            'title': 'Create Personalised Cat Photo Albums with Jigpaws',
            'description': 'Here at Jigpaws, making your own, fully personalised cat photo album has never been easier!',
            // 'description2': 'Once you have completed your fully customisable and personalised ' + 'cat gifts, you can ship them absolutely free to anywhere in the UK!'
        }, 'panel_1': {
            'title': 'Personalised Cat Photo Books',
            'description': "If you want to cherish the unique bond you share with your beloved feline friend, you can create a personalised cat photo album with Jigpaws. Capture all their quirky behaviours and distinctive personality traits on your phone or tablet, and every time you flip through your cat photo book, it will bring a smile to your face! \n" +
            '\n'+
            'At Jigpaws, we offer a sturdy, library-bound, hardback cat photo album containing 20 images of your feline friend in each of their moods, then order directly to your door (free shipping is available).',
            'image': {
                'src': '/images/landingPages/photo-books/cat1.jpg',
                'alt': 'Jigpaws personalised gift photobook with cat photo'
            }
        }, 'panel_2': {
            'title': 'Add 20 Images',
            'description': 'Browse through images of your favourite family friend with your brand new, hardback personalised cat photo album, today! Using our easy upload tool, all you need to do is simply add your images straight to your new cat photo book, and they will appear immediately. \n' +
                '\n' +
                'Then, add style and flair with a range of filters, effects and much more to your new cat photo album, using our simple editing tool. With hundreds of ways to purr-fect your personalised cat photo book, the decision is yours!',
            // 'description2': 'Choose an existing photo in our editing tool and see your new personalised pet picture frame come alive. Your image is printed onto a wooden insert in full detail and vibrant colour. ',
            'image': {
                'src': '/images/landingPages/uploadphotobook.jpg',
                'alt': 'Upload your image with Jigpaws',
            }
        }, 'panel_3': {
            'title': 'Pick Your Size',
            'description': 'Each Jigpaws product is made by hand, meaning that we can offer 2 sizes of photo book:',
            'list': '• 14x14cm\n' +
                '• 21x21cm\n',
            'image': {
                'src': '/images/landingPages/photo-books/cat2.jpg',
                'alt': 'Choose your photobook with Jigpaws'
            }
        }, 'panel_4': {
            'title': 'Order Your Cat Photo Album',
            'description': 'With free shipping available to the mainland of the UK, Jigpaws can have your new, personalised cat photo book with you within 3 - 7 working days.',
            // 'description2': '• 30 x 30cm\n • 46 x 46cm\n',
            // 'list': '• 30 x 30cm\n • 46 x 46cm\n',
            'image': {
                'src': '/images/landingPages/photo-books/cat3.jpg',
                'alt': 'Jigpaws personalised photo-book with cat photo'
            }
        },
    },
    // SECTION CARDS
    'pet-cards': {
        'consistent':{
            'createLink': '/product/greeting-card/',
            'price': 'matte-greeting-card',
            'priceFrom': false,
        },
        'head': {
            'title': 'Create Detailed Pet Cards | Order Online | Jigpaws'
        }, 'meta': {
            'description': 'Create your own custom-made pet cards with us here at Jigpaws using our dedicated online tool. Simply pick your pet picture and see it on a card, instantly!',
            'og_url': 'https://www.jigpaws.com/gift-products/pet-cards',
            'og_title': 'Create Detailed Pet Cards | Order Online | Jigpaws',
            'og_description': 'Create your own custom-made pet cards with us here at Jigpaws using our dedicated online tool. Simply pick your pet picture and see it on a card, instantly!',
            'og_image': 'https://www.jigpaws.com/images/Jigpaws-social-2.jpg',
        }, 'header': {
            'title': 'Create Unique Pet Cards with Jigpaws',
            'description': 'Welcome to Jigpaws, where making your own, fully customisable pet cards has never been easier!',
            // 'description2': 'Once you have completed your fully customisable and personalised ' + 'cat gifts, you can ship them absolutely free to anywhere in the UK!'
        }, 'panel_1': {
            'title': 'Create Your Pet Cards, Instantly!',
            'description': "What better way to show your pet-loving pal that you care than with a custom-made and totally unique pet card from us here at Jigpaws? Simply upload your image to our simple editing tool and see it instantly on a 170gsm card! \n" +
            '\n'+
            'Order 10 or more custom cards today to receive 30% off! ',
            'image': {
                'src': '/images/landingPages/cards/pet1.jpg',
                'alt': 'Jigpaws personalised card with pet photo'
            }
        }, 'panel_2': {
            'title': 'Add an Image to Your Pet Cards',
            'description': 'Simply upload your image using your smartphone, laptop or tablet easily using our online tool and see it on a pet card, instantly.',
            // 'description2': 'Choose an existing photo in our editing tool and see your new personalised pet picture frame come alive. Your image is printed onto a wooden insert in full detail and vibrant colour. ',
            'image': {
                'src': '/images/landingPages/uploadCard.jpg',
                'alt': 'Upload your image with Jigpaws',
            }
        }, 'panel_3': {
            'title': 'Add Flair to Your Pet Cards',
            'description': 'You can see your new pet cards in real-time, and add filters to make your pet stand out from the page. You can also add emojis and annotations, too!\n' +
                'You can also now pick between a matte and gloss print. This makes your new pet card come alive with either a shiny and vibrant gloss finish or a stylish matte texture. \n' +
                'Your choice of finishes:\n',
            'list': '• Matte\n' +
                '• Gloss\n',
            'image': {
                'src': '/images/landingPages/cards/pet2.jpg',
                'alt': 'Choose your card with Jigpaws'
            }
        }, 'panel_4': {
            'title': 'Order Your Pet Cards To Your Door',
            'description': 'With free shipping available to the mainland United Kingdom, Jigpaws can have your new, custom pet cards with you within 2 - 5 working days. International shipping is also available.',
            // 'description2': '• 30 x 30cm\n • 46 x 46cm\n',
            // 'list': '• 30 x 30cm\n • 46 x 46cm\n',
            'image': {
                'src': '/images/landingPages/cards/pet3.jpg',
                'alt': 'Jigpaws personalised card with pet photo'
            }
        },
    },
    'dog-cards': {
        'consistent':{
            'createLink': '/product/greeting-card/',
            'price': 'matte-greeting-card',
            'priceFrom': false,
        },
        'head': {
            'title': 'Create Personalised Dog Cards | Order Online | Jigpaws'
        }, 'meta': {
            'description': 'Create personalised dog cards with Jigpaw’s online editing tool. Quickly see your dog\'s picture on a card with many different editing options available.',
            'og_url': 'https://www.jigpaws.com/gift-products/dog-cards',
            'og_title': 'Create Personalised Dog Cards | Order Online | Jigpaws',
            'og_description': 'Create personalised dog cards with Jigpaw’s online editing tool. Quickly see your dog\'s picture on a card with many different editing options available.',
            'og_image': 'https://www.jigpaws.com/images/Jigpaws-social-2.jpg',
        }, 'header': {
            'title': 'Create Unique Dog Cards with Jigpaws',
            'description': 'Welcome to Jigpaws, where designing your own, fully personalised dog cards has never been easier!',
            // 'description2': 'Once you have completed your fully customisable and personalised ' + 'cat gifts, you can ship them absolutely free to anywhere in the UK!'
        }, 'panel_1': {
            'title': 'Create Your Dog Cards Today',
            'description': "Are you looking for a unique way to cherish and remember your furry friend? Or do you want to show someone how much you care about them and their dog? Jigpaws lets you create fully personalised dog cards that perfectly accompany a unique gift. \n" +
            '\n'+
            'Simply upload your favourite dog image to see your brand-new cards in high detail and vibrant colour. Now with your choice of printing on glossy or matte 170gsm card! \n' +
                '\n'+
                'Order 10 or more unique cards today to receive 30% off! \n',

            'image': {
                'src': '/images/landingPages/cards/dog1.jpg',
                'alt': 'Jigpaws personalised card with dog photo'
            }
        }, 'panel_2': {
            'title': 'Add A Picture to Your Dog Card\n',
            'description': 'Uploading your image to Jigpaws is simple and quick. You can see your new dog cards in real-time and add filters to enhance their colour. Apply sepia tones or create a stark black-and-white effect instantly, or add annotations and even emojis! Make the perfect accompaniment to any gift (or even keep one for yourself).\n',
            // 'description2': 'Choose an existing photo in our editing tool and see your new personalised pet picture frame come alive. Your image is printed onto a wooden insert in full detail and vibrant colour. ',
            'image': {
                'src': '/images/landingPages/uploadCard.jpg',
                'alt': 'Upload your image with Jigpaws',
            }
        }, 'panel_3': {
            'title': 'Choose Your Paper Print',
            'description': 'Our dog cards are printed on high-quality, 170gsm card and are available with your choice of finishes. \n' +
                'Your card can be finished in either:\n',
            'list': '• Matte\n' +
                '• Gloss\n',
            'image': {
                'src': '/images/landingPages/cards/dog2.jpg',
                'alt': 'Choose your card with Jigpaws'
            }
        }, 'panel_4': {
            'title': 'Order Your Dog Cards To Your Door',
            'description': 'Free shipping is available for all our dog cards in 2 - 5 working days to all mainland UK destinations. International shipping is also available. \n',
            // 'description2': '• 30 x 30cm\n • 46 x 46cm\n',
            // 'list': '• 30 x 30cm\n • 46 x 46cm\n',
            'image': {
                'src': '/images/landingPages/cards/dog3.jpg',
                'alt': 'Jigpaws personalised card with dog photo'
            }
        },
    },
    'cat-cards': {
        'consistent':{
            'createLink': '/product/greeting-card/',
            'price': 'matte-greeting-card',
            'priceFrom': false,
        },
        'head': {
            'title': 'Create Unique Cat Cards | Order Online | Jigpaws'
        }, 'meta': {
            'description': 'Create personalised cat cards instantly with Jigpaws. Add to your kitty collection with free delivery available and many different items to choose from.',
            'og_url': 'https://www.jigpaws.com/gift-products/cat-cards',
            'og_title': 'Create Unique Cat Cards | Order Online | Jigpaws',
            'og_description': 'Create personalised cat cards instantly with Jigpaws. Add to your kitty collection with free delivery available and many different items to choose from.',
            'og_image': 'https://www.jigpaws.com/images/Jigpaws-social-2.jpg',
        }, 'header': {
            'title': 'Create Custom Cat Cards with Jigpaws',
            'description': 'Here at Jigpaws, making and printing your own personalised cat cards has never been easier!',
            // 'description2': 'Once you have completed your fully customisable and personalised ' + 'cat gifts, you can ship them absolutely free to anywhere in the UK!'
        }, 'panel_1': {
            'title': 'Create Your Cat Cards',
            'description': "Looking for a special way to remember your fuzzy friend or show someone how much you care about them and their cat? Jigpaws offers fully customed and personalised cat cards that are perfect as a unique gift. Purr-fect for birthdays, anniversaries or any occasion, creating your own unique cat cards has never been easier. \n" +
            '\n'+
            'Simply upload your favourite cat image to see your brand-new cards in high detail, vibrant colour and printed on your choice of glossy or matte finish.',

            'image': {
                'src': '/images/landingPages/cards/cat1.jpg',
                'alt': 'Jigpaws personalised card with cat photo'
            }
        }, 'panel_2': {
            'title': 'Add An Image To Your Cat Cards',
            'description': 'Your cat card will be printed on a high-quality 170gsm card and is available with your choice of finishes.\n' +
                '\n'+
            'Now with easy options to flip and reposition your image, you can adjust your image until it looks precisely how you want it.',

            // 'description2': 'Choose an existing photo in our editing tool and see your new personalised pet picture frame come alive. Your image is printed onto a wooden insert in full detail and vibrant colour. ',
            'image': {
                'src': '/images/landingPages/uploadCard.jpg',
                'alt': 'Upload your image with Jigpaws',
            }
        }, 'panel_3': {
            'title': 'Choose Your Paper Print',
            'description': 'Your cat card will be printed on a high-quality 170gsm card and is available with your choice of finishes.\n',
            'list': '• Gloss Finish\n' +
                '• Matte Finish\n',
            'image': {
                'src': '/images/landingPages/cards/cat2.jpg',
                'alt': 'Choose your card with Jigpaws'
            }
        }, 'panel_4': {
            'title': 'Order Your Cat Cards To Your Door',
            'description': 'Free shipping is available on our entire range of cat cards within 2 - 5 working days to the mainland UK. International shipping is also available.',
            // 'description2': '• 30 x 30cm\n • 46 x 46cm\n',
            // 'list': '• 30 x 30cm\n • 46 x 46cm\n',
            'image': {
                'src': '/images/landingPages/cards/cat3.jpg',
                'alt': 'Jigpaws personalised card with cat photo'
            }
        },
    },
    // SECTION NOTEBOOKS
    'pet-notebooks': {
        'consistent':{
            'createLink': '/product/journal/',
            'price': 'lined-journal',
            'priceFrom': false,
        },
        'head': {
            'title': 'Create Your Pet Notebook | Order Online | Jigpaws'
        }, 'meta': {
            'description': 'Create your own personalised pet notebooks with us here at Jigpaws by using our dedicated online tool. Simply pick your pet picture and see it on a notebook, instantly!',
            'og_url': 'https://www.jigpaws.com/gift-products/pet-notebooks',
            'og_title': 'Create Your Pet Notebook | Order Online | Jigpaws',
            'og_description': 'Create your own personalised pet notebooks with us here at Jigpaws by using our dedicated online tool. Simply pick your pet picture and see it on a notebook, instantly!',
            'og_image': 'https://www.jigpaws.com/images/Jigpaws-social-2.jpg',
        }, 'header': {
            'title': 'Create Personalised Pet Notebooks with Jigpaws',
            'description': 'Welcome to Jigpaws, where making your own, fully customisable pet notebook has never been easier!',
            // 'description2': 'Simply upload your favourite pet image to see your brand-new notebook in high detail, vibrant colours and always printed on a glossy, hardback cover. '
        }, 'panel_1': {
            'title': 'Create Your Pet Notebook',
            'description': "Are you looking for a unique way to cherish your or someone else’s beloved pet? Or, are you looking for a great way to give someone an extra special gift featuring their pet pal? Now you can create a fully personalised pet notebook with Jigpaws.\n" +
            '\n'+
            'Simply upload your favourite pet image to see your brand-new notebook in high detail, vibrant colours and always printed on a glossy, hardback cover.',

            'image': {
                'src': '/images/landingPages/notebooks/pet1.jpg',
                'alt': 'Jigpaws personalised card with pet photo'
            }
        }, 'panel_2': {
            'title': 'Add Your Image',
            'description': 'Uploading your image to Jigpaws is a straightforward and secure process. You can see your new pet notebook in real-time and add filters to enhance its vibrancy, apply sepia tones or create a stark black-and-white effect.\n' +
                '\n'+
                'With easy flip and reposition options, you can adjust your image until your pet image really stands out on your notebook.',

            // 'description2': 'Choose an existing photo in our editing tool and see your new personalised pet picture frame come alive. Your image is printed onto a wooden insert in full detail and vibrant colour. ',
            'image': {
                'src': '/images/landingPages/uploadNotebook.jpg',
                'alt': 'Upload your image with Jigpaws',
            }
        }, 'panel_3': {
            'title': 'Choose Your Paper Print',
            'description': 'Our library-bound notebooks are available with your choice of paper style on the inside. Each comes with 64 interior pages of your choice:\n',
            'list': '• Lined Paper\n' +
                '• Graph Paper \n'+
                            '• Plain White Paper\n',

            'image': {
                'src': '/images/landingPages/notebooks/pet2.jpg',
                'alt': 'Choose your card with Jigpaws'
            }
        }, 'panel_4': {
            'title': 'Order Your Pet Notebook To Your Door',
            'description': 'With free shipping available to mainland UK, Jigpaws can have your new, custom pet notebook with you within 2 - 5 working days. International shipping is also available.',
            // 'description2': '• 30 x 30cm\n • 46 x 46cm\n',
            // 'list': '• 30 x 30cm\n • 46 x 46cm\n',
            'image': {
                'src': '/images/landingPages/notebooks/pet3.jpg',
                'alt': 'Jigpaws personalised notebook with pet photo'
            }
        },
    },
    'dog-notebooks': {
        'consistent':{
            'createLink': '/product/journal/',
            'price': 'lined-journal',
            'priceFrom': false,
        },
        'head': {
            'title': 'Create Your Dog Notebook | Order Online | Jigpaws'
        }, 'meta': {
            'description': 'Create personalised dog notebooks with Jigpaws\' online editing tool. Quickly see your pet\'s picture on a notebook with lots of different editing options available.',
            'og_url': 'https://www.jigpaws.com/gift-products/dog-notebooks',
            'og_title': 'Create Your Dog Notebook | Order Online | Jigpaws',
            'og_description': 'Create personalised dog notebooks with Jigpaws\' online editing tool. Quickly see your pet\'s picture on a notebook with lots of different editing options available.',
            'og_image': 'https://www.jigpaws.com/images/Jigpaws-social-2.jpg',
        }, 'header': {
            'title': 'Create Personalised Dog Notebooks with Jigpaws',
            'description': 'Welcome to Jigpaws, where making your own, fully customisable dog notebook has never been easier!',
            // 'description2': 'Simply upload your favourite pet image to see your brand-new notebook in high detail, vibrant colours and always printed on a glossy, hardback cover. '
        }, 'panel_1': {
            'title': 'Create Your Dog Notebook',
            'description': "Are you looking for a special way to remember your loyal companion, create a keepsake for someone special or pay homage to a furry friend? Jigpaws offers fully personalised dog notebooks that are perfect as a unique gift. Ideal for study, work or jotting down ideas, our dog notepads are a real treat for any dog lover. \n" +
            '\n'+
            'Simply upload your favourite dog image to see your brand-new notebook in high detail, vibrant colours and printed on a glossy, hardback cover. \n',

            'image': {
                'src': '/images/landingPages/notebooks/dog1.jpg',
                'alt': 'Jigpaws personalised card with pet photo'
            }
        }, 'panel_2': {
            'title': 'Add Your Image',
            'description': 'Uploading your image to Jigpaws is a straightforward and secure process. You can see your new dog notebook in real-time and add filters to enhance its vibrancy, apply sepia tones or create a stark black-and-white effect.\n' +
                '\n'+
                'With easy flip and reposition options, you can adjust your image until your dog is bounding off the page!',

            // 'description2': 'Choose an existing photo in our editing tool and see your new personalised pet picture frame come alive. Your image is printed onto a wooden insert in full detail and vibrant colour. ',
            'image': {
                'src': '/images/landingPages/uploadNotebook.jpg',
                'alt': 'Upload your image with Jigpaws',
            }
        }, 'panel_3': {
            'title': 'Choose Your Paper Print',
            'description': 'Our library-bound notebooks are available with your choice of printed paper interiors. Each comes with 64 pages of your choice of:\n',
            'list': '• Lined Paper\n' +
                '• Graph Paper \n'+
                            '• Plain White Paper\n',

            'image': {
                'src': '/images/landingPages/notebooks/dog2.jpg',
                'alt': 'Choose your card with Jigpaws'
            }
        }, 'panel_4': {
            'title': 'Order Your Dog Notebook To Your Door',
            'description': 'Free shipping is available for all dog notebooks within 2 - 5 working days to mainland UK. International shipping is also available.',
            // 'description2': '• 30 x 30cm\n • 46 x 46cm\n',
            // 'list': '• 30 x 30cm\n • 46 x 46cm\n',
            'image': {
                'src': '/images/landingPages/notebooks/dog3.jpg',
                'alt': 'Jigpaws personalised notebook with dog photo'
            }
        },
    },
    'cat-notebooks': {
        'consistent':{
            'createLink': '/product/journal/',
            'price': 'lined-journal',
            'priceFrom': false,
        },
        'head': {
            'title': 'Create Cat Notebooks | Order Online | Jigpaws'
        }, 'meta': {
            'description': 'Create personalised cat notebooks instantly with Jigpaws. Add to your kitty collection with free delivery available and many different items to choose from. \n',
            'og_url': 'https://www.jigpaws.com/gift-products/cat-notebooks',
            'og_title': 'Create Your Cat Notebook | Order Online | Jigpaws',
            'og_description': 'Create personalised cat notebooks instantly with Jigpaws. Add to your kitty collection with free delivery available and many different items to choose from. \n',
            'og_image': 'https://www.jigpaws.com/images/Jigpaws-social-2.jpg',
        }, 'header': {
            'title': 'Create a Custom Cat Notebook with Jigpaws',
            'description': 'Here at Jigpaws, making and printing your own personalised cat notebook has never been easier!',
            // 'description2': 'Simply upload your favourite pet image to see your brand-new notebook in high detail, vibrant colours and always printed on a glossy, hardback cover. '
        }, 'panel_1': {
            'title': 'Create Your Cat Notebook',
            'description': "Looking for a special way to remember your cuddly friend or show someone how much you care about their cat? Jigpaws offers fully personalised cat notebooks that are perfect as a unique gift. Ideal for work, study or for jotting down ideas, our cat notepads are a real treat for any cat lover.\n" +
            '\n'+
            'Simply upload your favourite cat image to see your brand-new notebook in high detail, vibrant colour and printed on a glossy, hardback cover.\n',

            'image': {
                'src': '/images/landingPages/notebooks/cat1.jpg',
                'alt': 'Jigpaws personalised notebook with dog photo'
            }
        }, 'panel_2': {
            'title': 'Add Your Image',
            'description': 'Uploading an image to your cat notebook on Jigpaws is a simple, straightforward and secure process. You can see your new cat notebook in real-time and add filters to enhance its vibrancy, apply sepia tones or create a stark black-and-white, contrasted effect.\n' +
                '\n'+
                'Now with easy options to flip and reposition your image, you can adjust your image until your new cat notebook springs to life!',

            // 'description2': 'Choose an existing photo in our editing tool and see your new personalised pet picture frame come alive. Your image is printed onto a wooden insert in full detail and vibrant colour. ',
            'image': {
                'src': '/images/landingPages/uploadNotebook.jpg',
                'alt': 'Upload your image with Jigpaws',
            }
        }, 'panel_3': {
            'title': 'Choose Your Paper Print',
            'description': 'Our library-bound notebooks are available with your preferred interior paper style. Each comes with 64 pages of your choice of:\n',
            'list': '• Lined Paper\n' +
                '• Graph Paper \n'+
                            '• Plain White Paper\n',

            'image': {
                'src': '/images/landingPages/notebooks/cat2.jpg',
                'alt': 'Choose your notebook with Jigpaws'
            }
        }, 'panel_4': {
            'title': 'Order Your Cat Notebook To Your Door',
            'description': 'Free shipping is available for all cat notebooks within 2 - 5 working days to the UK mainland. International shipping is also available.',
            // 'description2': '• 30 x 30cm\n • 46 x 46cm\n',
            // 'list': '• 30 x 30cm\n • 46 x 46cm\n',
            'image': {
                'src': '/images/landingPages/notebooks/cat3.jpg',
                'alt': 'Jigpaws personalised notebook with cat photo'
            }
        },
    },
    // SECTION CUSHIONS
    'pet-cushions': {
        'consistent':{
            'createLink': '/product/cushion',
            'price': 'suede-cushion-12x12',
            'priceFrom': 'true',
        },
        'head': {
            'title': 'Personalised Pet Cushions | Create | Jigpaws'
        }, 'meta': {
            'description': 'Create personalised pet cushions  with us here at Jigpaws with options to customise your Jigsaws and ship them directly to your door. Create with Jigpaws today!',
            'og_url': 'https://www.jigpaws.com//gift-products/pet-cushions',
            'og_title': 'Personalised Pet Cushions | Create | Jigpaws',
            'og_description': 'Create personalised pet cushions with us here at Jigpaws with options to customise your Jigsaws and ship them directly to your door. Create with Jigpaws today!',
            'og_image': 'https://www.jigpaws.com/images/Jigpaws-social-2.jpg',
        }, 'header': {
            'title': 'Create Personalised Pet Cushions with Jigpaws',
            'description': 'Welcome to Jigpaws, where making your own, fully personalised pet cushion has never been simpler!',
            // 'description2': 'Once you have completed your fully customisable and personalised ' + 'cat gifts, you can ship them absolutely free to anywhere in the UK!'
        }, 'panel_1': {
            'title': 'Personalised Pet Cushions',
            'description': 'Are you looking for a unique way to cherish your beloved pet? Now you can create fully personalised pet cushions with us here at Jigpaws. From a happy hamster to a legendary lizard, simply upload your image to Jigpaws and you can see your new extra comfy cushion, instantly!',
            'image': {
                'src': '/images/landingPages/cushions/pet1.jpg',
                'alt': 'Jigpaws personalised gift cushion with pet photo'
            }
        }, 'panel_2': {
            'title': 'Add Your Image',
            'description': 'Get ready to unwind with your new fully personalised pet cushion, today! Using our simple upload tool, all you need to do is simply add an image to see it immediately on your new cushion.',
            'description2': ' Add style and flair with a range of filters, effects and much more to your new cushion, with our simple editing tool. With hundreds of ways to perfect your personalised pet cushion, the decision is yours!',
            'image': {
                'src': '/images/landingPages/uploadCushion.jpg',
                'alt': 'Upload your image with Jigpaws',
            }
        }, 'panel_3': {
            'title': 'Pick Your Size',
            'description': 'Each Jigpaws product is made by hand, meaning that we can offer 2 sizes of cushion:',
            'list': '• 30x30cm\n' +
                '• 46x46cm \n',
            'image': {
                'src': '/images/landingPages/cushions/pet2.jpg',
                'alt': 'choose your cushion with Jigpaws'
            }
        }, 'panel_4': {
            'title': 'Order To Your Door',
            'description': 'With free shipping available to mainland UK, Jigpaws can have your new, personalised pet cushion with you within 3 - 7 working days.',
            'image': {
                'src': '/images/landingPages/cushions/pet3' +
                    '.jpg',
                'alt': 'Jigpaws personalised gift cushion with pet photo'
            }
        },
    },'dog-cushions': {
        'consistent':{
            'createLink': '/product/cushion',
            'price': 'suede-cushion-12x12',
            'priceFrom': 'true',
        },
        'head': {
            'title': 'Personalised Dog Cushions | Create | Jigpaws'
        }, 'meta': {
            'description': 'Create personalised dog cushions  with us here at Jigpaws with options to customise your Jigsaws and ship them directly to your door. Create with Jigpaws today!',
            'og_url': 'https://www.jigpaws.com//gift-products/dog-cushions',
            'og_title': 'Personalised Pet Cushions | Create | Jigpaws',
            'og_description': 'Create personalised dog cushions with us here at Jigpaws with options to customise your Jigsaws and ship them directly to your door. Create with Jigpaws today!',
            'og_image': 'https://www.jigpaws.com/images/Jigpaws-social-2.jpg',
        }, 'header': {
            'title': 'Create Personalised Pet Cushions with Jigpaws',
            'description': 'Welcome to Jigpaws, where you can create your own personalised dog cushion in minutes!',
            // 'description2': 'Once you have completed your fully customisable and personalised ' + 'cat gifts, you can ship them absolutely free to anywhere in the UK!'
        }, 'panel_1': {
            'title': 'Personalised Pet Cushions',
            'description': 'Do you love your furry friends and want to show them off in style? Our cushions are made with soft and cosy faux-suede covers that you can customise with any dog photo you want! Whether it’s to celebrate a new puppy or a loyal companion, just upload your photo to Jigpaws and you can see your new snuggly cushion, instantly.',
            'image': {
                'src': '/images/landingPages/cushions/dog1.jpg',
                'alt': 'Jigpaws personalised gift cushion with dog photo'
            }
        }, 'panel_2': {
            'title': 'Add Your Image',
            'description': 'Get ready to relax with your new personalised dog cushion, today! Using our simple upload tool, all you need to do is add your dog image and it will appear immediately on your new cushion.',
            'description2': 'Add style and flair with a range of filters, effects and much more to your new cushion, with our simple editing tool. With hundreds of ways to perfect your personalised dog cushion, the decision is completely up to you!',
            'image': {
                'src': '/images/landingPages/uploadCushion.jpg',
                'alt': 'Upload your image with Jigpaws',
            }
        }, 'panel_3': {
            'title': 'Pick Your Size',
            'description': 'Each Jigpaws product is made by hand, meaning that we can offer 2 sizes of cushion:',
            'list': '• 30x30cm\n' +
                '• 46x46cm \n',
            'image': {
                'src': '/images/landingPages/cushions/pet2.jpg',
                'alt': 'choose your cushion with Jigpaws'
            }
        }, 'panel_4': {
            'title': 'Order To Your Door',
            'description': 'With free shipping available to mainland UK, Jigpaws can have your new, personalised dog cushion with you within 3 - 7 working days.',
            'image': {
                'src': '/images/landingPages/cushions/pet3' +
                    '.jpg',
                'alt': 'Jigpaws personalised gift cushion with pet photo'
            }
        },
    },
    'cat-cushions': {
        'consistent':{
            'createLink': '/product/cushion',
            'price': 'suede-cushion-12x12',
            'priceFrom': 'true',
        },
        'head': {
            'title': 'Personalised Cat Cushions | Create | Jigpaws'
        }, 'meta': {
            'description': 'Create personalised cat cushions  with us here at Jigpaws with options to customise your Jigsaws and ship them directly to your door. Create with Jigpaws today!',
            'og_url': 'https://www.jigpaws.com//gift-products/cat-cushions',
            'og_title': 'Personalised Cat Cushions | Create | Jigpaws',
            'og_description': 'Create personalised cat cushions with us here at Jigpaws with options to customise your Jigsaws and ship them directly to your door. Create with Jigpaws today!',
            'og_image': 'https://www.jigpaws.com/images/Jigpaws-social-2.jpg',
        }, 'header': {
            'title': 'Create Personalised Cat Cushions with Jigpaws',
            'description': 'Welcome to Jigpaws, where you can create your own personalised dog cushion in minutes!',
            // 'description2': 'Once you have completed your fully customisable and personalised ' + 'cat gifts, you can ship them absolutely free to anywhere in the UK!'
        }, 'panel_1': {
            'title': 'Personalised Cat Cushions',
            'description': 'Do you adore your purring pal and want to pamper them in style? Our cushions are made with soft and cosy faux-suede covers that you can customise with your favourite picture of your beloved cat! Whether it’s to celebrate a new kitten or an old, faithful friend, just upload your photo to Jigpaws and you can see your new cuddly cushion, instantly!',
            'image': {
                'src': '/images/landingPages/cushions/cat1.jpg',
                'alt': 'Jigpaws personalised gift cushion with cat photo'
            }
        }, 'panel_2': {
            'title': 'Add Your Image',
            'description': 'Get ready for a cozie cat nap with your new personalised cat cushion, today! Using our easy upload tool, all you need to do is simply add an image and it will appear on  your new cushion mmediately.',
            'description2': 'Add style and flair with a range of filters, effects and much more to your new cushion, with our simple editing tool. With hundreds of ways to perfect your personalised cat cushion, the decision is yours!',
            'image': {
                'src': '/images/landingPages/uploadCushion.jpg',
                'alt': 'Upload your image with Jigpaws',
            }
        }, 'panel_3': {
            'title': 'Pick Your Size',
            'description': 'Each Jigpaws product is made by hand, meaning that we can offer 2 sizes of cushion:',
            'list': '• 30x30cm\n' +
                '• 46x46cm \n',
            'image': {
                'src': '/images/landingPages/cushions/cat2.jpg',
                'alt': 'choose your cushion with Jigpaws'
            }
        }, 'panel_4': {
            'title': 'Order To Your Door',
            'description': 'With free shipping available to mainland UK, Jigpaws can have your new, personalised cat cushion with you within 3-5 working days.',
            'image': {
                'src': '/images/landingPages/cushions/cat3.jpg',
                'alt': 'Jigpaws personalised gift cushion with pet photo'
            }
        },
    },
    'personalised-cushions': {
        'consistent':{
            'createLink': '/product/cushion',
            'price': 'suede-cushion-12x12',
            'priceFrom': 'true',
        },
        'head': {
            'title': 'Create Personalised Cushions | Custom Cushions | Jigpaws'
        }, 'meta': {
            'description': 'Create and personalise your own unique custom cushions with us here at Jigpaws. You can order online and receive free shipping with every order.',
            'og_url': 'https://www.jigpaws.com//gift-products/personalised-cushions',
            'og_title': 'Create Personalised Cushions | Custom Cushions | Jigpaws',
            'og_description': 'Create and personalise your own unique custom cushions with us here at Jigpaws. You can order online and receive free shipping with every order',
            'og_image': 'https://www.jigpaws.com/images/Jigpaws-social-2.jpg',
        }, 'header': {
            'title': 'Create Personalised Cushions with Jigpaws',
            'description': 'At Jigpaws, creating personalised cushions is easier than ever before. Create your custom cushion, and order it right to your door, with free UK mainland shipping available.',
            // 'description2': 'Once you have completed your fully customisable and personalised ' + 'cat gifts, you can ship them absolutely free to anywhere in the UK!'
        }, 'panel_1': {
            'title': 'Create A Personalised Cushion in a Few Simple Steps',
            'description': 'Making a custom cushion is a great gift for a birthday or special occasion to decorate your home, or to show someone you care. \n\n With Jigaws, creating a high-quality personalised cushion using your own picture is quick, simple and secure!',

            'image': {
                'src': '/images/landingPages/cushions/generic1.webp',
                'alt': 'Jigpaws personalised gift cushion'
            }
        }, 'panel_2': {
            'title': 'Simply Add An Image',
            'description': 'Using our online tool, you can upload a picture from your phone, computer or tablet in a few simple clicks. ',
            'description2': 'You can add effects and filters to your picture, or crop the image to get it exactly how you want it to look. You’ll then be able to see your brand-new, custom cushion come to life!',
            'image': {
                'src': '/images/landingPages/uploadCushion.jpg',
                'alt': 'Upload your image with Jigpaws',
            }
        }, 'panel_3': {
            'title': 'Pick Your Size',
            'description': 'Your new, fully custom cushion will be printed with a removable faux-suede cover and come complete with a plush interior. \n\n Our custom cushions come in sizes 30 x 30cm or 46 x 46cm, and each is machine washable.',
            'image': {
                'src': '/images/landingPages/cushions/generic2.webp',
                'alt': 'choose your cushion with Jigpaws'
            }
        }, 'panel_4': {
            'title': 'Order To Your Door',
            'description': 'Once you have your personalised photo cushion just right, you can order it to your door, with free shipping available to mainland UK destinations. Please allow 3 - 7 working days for your cushion to arrive. ',
            'image': {
                'src': '/images/landingPages/cushions/generic3.webp',
                'alt': 'Jigpaws personalised gift cushion',
            }
        },  'signOffSlider': {
            'title': 'Create Custom Cushions And Much, Much More!',
            'description': 'Here at Jigpaws, we don’t just create stylish cushions for you to get comfy with, but we also make Personalised Jigsaws, Custom Notebooks, and so much more! Why not view the rest of our range with Jigpaws?  ',
            'list': '• Cards\n • Notebooks\n • Photo Tiles\n • Photo Books\n  • Jigsaws\n',
        },
    },

    //SECTION JIGSAWS
    'pet-jigsaws': {
        'consistent':{
            'createLink': '/product/jigsaw',
            'price': 'jigsaw-500-50x38',
            'priceFrom': 'true',
        },
        'head': {
            'title': 'Personalised Pet Jigsaw Puzzles | Create | Jigpaws'
        }, 'meta': {
            'description': 'Create personalised pet jigsaw puzzles with us here at Jigpaws with options to customise your Jigsaws and ship them directly to your door. Create with Jigpaws today!',
            'og_url': 'https://www.jigpaws.com//gift-products/pet-jigsaws"',
            'og_title': 'Personalised Pet Jigsaw Puzzles | Create | Jigpaws',
            'og_description': 'Create personalised pet jigsaw puzzles with us here at Jigpaws with options to customise your Jigsaws and ship them directly to your door. Create with Jigpaws today!',
            'og_image': 'https://www.jigpaws.com/images/Jigpaws-social-2.jpg',
        }, 'header': {
            'title': 'Create Personalised Pet Jigsaw Puzzles with Jigpaws',
            'description': 'Welcome to Jigpaws, where creating your own fully personalised pet jigsaw puzzle has never been easier.',
            // 'description2': 'Once you have completed your fully customisable and personalised ' + 'cat gifts, you can ship them absolutely free to anywhere in the UK!'
        }, 'panel_1': {
            'title': 'PERSONALISED Pet JIGSAWS',
            'description': 'Are you looking for a unique way to enjoy your favourite pet photos? Why not create a personalised jigsaw puzzle? With options for either 500 or 1000 pieces, this puzzle is perfect for those seeking a challenge and casual puzzlers alike. It also comes in a beautiful presentation box - making it a great treat for yourself or as a gift for family and friends.',
            'image': {
                'src': '/images/landingPages/jigsaws/pet1.jpg',
                'alt': 'Jigpaws personalised gift jigsaw with pet photo'
            }
        }, 'panel_2': {
            'title': 'Add Your Image',
            'description': 'To create a fully personalised pet jigsaw, simply upload a high-quality photo, which can either be taken from a tablet, smartphone or digital camera.\n',
            'description2': 'Easily add style and flair with a range of filters, effects and much more to your new personalised pet jigsaw, with our simple editing tool.',
            'image': {
                'src': '/images/landingPages/uploadJigsaw.jpg',
                'alt': 'Upload photos with Jigpaws'
            }
        }, 'panel_3': {
            'title': 'Pick Your Size',
            'description': 'Each Jigpaws puzzle is made by hand, meaning that we can offer 2 sizes:',
            'list': '• 500 pieces (50x38cm)\n' +
                '• 1000 pieces (66x50cm)\n',
            'image': {
                'src': '/images/landingPages/jigsaws/pet2.jpg',
                'alt': 'Jigpaws personalised gift jigsaw with pet photo'
            }
        }, 'panel_4': {
            'title': 'Order To Your Door',
            'description': 'With free shipping available to mainland UK, Jigpaws can have your new, personalised pet jigsaw puzzle with you within 3-5 working days.',
            // 'description2': 'They can be ordered with your option of:',
            'image': {
                'src': '/images/landingPages/jigsaws/pet3.jpg',
                'alt': 'Jigpaws personalised gift jigsaw with pet photo'
            }
            }, 'panel_5': {
            'title': 'Stylise And Add Flair',
            'description': 'Add stickers, effects, annotations and much more to your new jigsaw, or just keep the image blank if you prefer. With hundreds of ways to create your personalised pet jigsaw puzzle, the decision is yours!',
            'image': {
                'src': '/images/landingPages/stylise.jpg',
                'alt': 'Stylise your image with Jigpaws'
            }
        }, 'panel_6': {
            'title': 'Should there be a final CTA',
            'description': 'here?',
            // 'description2': 'At Jigpaws, we offer free shipping on all our cards, it ' + 'has never been easier to get strong, detailed and fully ' + 'personalised cat cards delivered straight to your door!',
            'image': {
                'src': '/images/products/cathome2023/products-cat-home-card.jpg',
                'alt': 'Jigpaws personalised gift photo card with pet cat photo'
            }
        },
    },
    'dog-jigsaws': {
        'consistent':{
            'createLink': '/product/jigsaw',
            'price': 'jigsaw-500-50x38',
            'priceFrom': 'true',
        },
        'head': {
            'title': 'Personalised Dog Jigsaw Puzzle | Create | Jigpaws'
        }, 'meta': {
            'description': 'Creating your own Personalised Dog Jigsaw Puzzle has never been easier than with Jigpaws. Simply upload your image, stylise and we ship it straight to your door!',
            'og_url': 'https://www.jigpaws.com//gift-products/dog-jigsaws"',
            'og_title': 'Personalised Dog Jigsaw Puzzle | Create | Jigpaws',
            'og_description': 'Creating your own Personalised Dog Jigsaw Puzzle has never been easier than with Jigpaws. Simply upload your image, stylise and we ship it straight to your door!',
            'og_image': 'https://www.jigpaws.com/images/Jigpaws-social-2.jpg',
        }, 'header': {
            'title': 'Create Personalised Dog Jigsaw Puzzles with Jigpaws',
            'description': 'Welcome to Jigpaws, a place where creating your own, fully customisable and personalised dog jigsaw puzzle has never been easier!\n',
            // 'description2': 'Once you have completed your fully customisable and personalised ' + 'cat gifts, you can ship them absolutely free to anywhere in the UK!'
        }, 'panel_1': {
            'title': 'PERSONALISED DOG JIGSAWS',
            'description': 'Looking for a unique way to enjoy your favourite dog photo? Then why not create a personalised jigsaw puzzle? With options for either 500 or 1000 pieces, this puzzle is perfect for both casual puzzlers and those seeking a challenge. Plus, it comes in a beautiful presentation box, making it a great gift for family and friends or a special treat for yourself!',
            'image': {
                'src': '/images/landingPages/jigsaws/dog1.jpg',
                'alt': 'Jigpaws personalised gift jigsaw with pet dog photo'
            }
        }, 'panel_2': {
            'title': 'Add Your Image',
            'description': 'To create a personalised dog jigsaw puzzle, simply upload a high-quality photo of your or someone else’s beloved pooch, which can be taken from a smartphone, tablet or digital camera. We will upload your image to your jigsaw in vibrant colour. ',
            'description2': 'Add style and flair with a range of filters, effects and much more to your new jigsaw, with our simple editing tool. With hundreds of ways to perfect your personalised dog jigsaw puzzle, the decision is yours!\n',
            'image': {
                'src': '/images/landingPages/uploadJigsaw.jpg',
                'alt': 'Upload a photo with Jigpaws'
            }
        }, 'panel_3': {
            'title': 'Pick Your Size',
            'description': 'Each Jigpaws puzzle is made by hand and is available in 2 sizes:\n',
            'list': '• 500 pieces (50x38cm)\n' +
                '• 1000 pieces (66x50cm)\n',
            'image': {
                'src': '/images/landingPages/jigsaws/dog2.jpg',
                'alt': 'Jigpaws personalised gift jigsaw with pet dog photo'
            }
        }, 'panel_4': {
            'title': 'Free Shipping Available',
            'description': 'With free shipping available to the mainland UK, Jigpaws can have your new, personalised dog jigsaw puzzle with you within 3-5 working days.',
            // 'description2': 'They can be ordered with your option of:',
            'image': {
                'src': '/images/landingPages/jigsaws/dog3.jpg',
                'alt': 'Jigpaws personalised gift jigsaw with pet dog photo'
            }
            }, 'panel_5': {
            'title': 'Stylise And Add Flair',
            'description': 'Add stickers, effects, annotations and much more to your jigsaw, or just keep the image blank if you would prefer. With hundreds of ways to create your personalised dog jigsaw puzzle, the decision is yours!',
            'image': {
                'src': '/images/landingPages/stylise.jpg',
                'alt': 'Stylise your photo with Jigpaws'
            }
        }, 'panel_6': {
            'title': 'Should there be a final CTA',
            'description': 'here?',
            // 'description2': 'At Jigpaws, we offer free shipping on all our cards, it ' + 'has never been easier to get strong, detailed and fully ' + 'personalised cat cards delivered straight to your door!',
            'image': {
                'src': '/images/landingPages/jigsaws/dog3.jpg',
                'alt': 'Jigpaws personalised gift jigsaw with pet dog photo'
            }
        },
    },
    'cat-jigsaws': {
        'consistent':{
            'createLink': '/product/jigsaw',
            'price': 'jigsaw-500-50x38',
            'priceFrom': 'true',
        },
        'head': {
            'title': 'Personalised Cat Jigsaw Puzzles | Create | Jigpaws'
        }, 'meta': {
            'description': 'Create personalised cat jigsaw puzzles with Jigpaws. Here, you can upload a picture of your cat and instantly see it on a jigsaw! Then you can order it straight to your door.\n',
            'og_url': 'https://www.jigpaws.com//gift-products/cat-jigsaws',
            'og_title': 'Personalised Cat Jigsaw Puzzles | Create | Jigpaws',
            'og_description': 'Create personalised cat jigsaw puzzles with Jigpaws. Here, you can upload a picture of your cat and instantly see it on a jigsaw! Then you can order it straight to your door.\n',
            'og_image': 'https://www.jigpaws.com/images/Jigpaws-social-2.jpg',
        }, 'header': {
            'title': 'Create Personalised Cat Jigsaw Puzzles with Jigpaws',
            'description': 'Welcome to Jigpaws, where you can create your own, fully customisable cat jigsaw puzzle online in seconds.\n',
            // 'description2': 'Once you have completed your fully customisable and personalised ' + 'cat gifts, you can ship them absolutely free to anywhere in the UK!'
        }, 'panel_1': {
            'title': 'PERSONALISED CAT JIGSAWS',
            'description': 'Are you searching for a unique way to enjoy or cherish your favourite cat photos? Are you wanting to pay homage to a friend\'s iconic cat? Then how about creating a fully personalised cat jigsaw puzzle? With options for either 500 or 1000 pieces, these cat jigsaw puzzles are purrrrfect for both casual puzzlers and those seeking a challenge.',
            'image': {
                'src': '/images/landingPages/jigsaws/cat1.jpg',
                'alt': 'Jigpaws personalised gift jigsaw with pet cat photo'
            }
        }, 'panel_2': {
            'title': 'Add Your Image',
            'description': 'Get ready to unwind and have fun with this fully unique, personalised cat jigsaw puzzle!',
            'description2': 'To create a personalised cat jigsaw puzzle, simply upload a high-quality photo of your or someone else’s beloved cat, which can be taken from a smartphone, tablet or digital camera. It will then appear in vibrant colour on your brand new jigsaw puzzle.',
            'image': {
                'src': '/images/landingPages/uploadJigsaw.jpg',
                'alt': 'Upload a photo with Jigpaws'
            }
        }, 'panel_3': {
            'title': 'Pick Your Size',
            'description': 'Each cat jigsaw puzzle is made by hand, meaning that we can offer 2 sizes:\n',
            'list': '• 500 pieces (50x38cm)\n' +
                '• 1000 pieces (66x50cm)\n',
            'image': {
                'src': '/images/landingPages/jigsaws/cat2.jpg',
                'alt': 'Jigpaws personalised gift jigsaw with pet cat photo'
            }
        }, 'panel_4': {
            'title': 'Delivered To You',
            'description': 'With free shipping available to the mainland UK, Jigpaws can have your new, personalised cat jigsaw puzzle with you within 3-5 working days.',
            // 'description2': 'They can be ordered with your option of:',
            'image': {
                'src': '/images/landingPages/jigsaws/cat3.jpg',
                'alt': 'Jigpaws personalised gift jigsaw with pet cat photo'
            }
            }, 'panel_5': {
            'title': 'Stylise And Add Flair',
            'description': 'With hundreds of ways to create your personalised cat jigsaw puzzle, the decision is yours! Add stickers, effects, annotations and much more to your jigsaw, or just keep the image if you prefer.',
            'image': {
                'src': '/images/landingPages/stylise.jpg',
                'alt': 'Stylise your photo with Jigpaws'
            }
        }, 'panel_6': {
            'title': 'Should there be a final CTA',
            'description': 'here?',
            // 'description2': 'At Jigpaws, we offer free shipping on all our cards, it ' + 'has never been easier to get strong, detailed and fully ' + 'personalised cat cards delivered straight to your door!',
            'image': {
                'src': '/images/products/cathome2023/products-cat-home-card.jpg',
                'alt': 'Jigpaws personalised gift photo card with pet cat photo'
            }
        },
    },
    'personalised-jigsaws': {
        'consistent':{
            'createLink': '/product/jigsaw',
            'price': 'jigsaw-500-50x38',
            'priceFrom': 'true',
        },
        'head': {
            'title': 'Personalised Jigsaw Puzzles | Make Your Own Jigsaw | Jigpaws'
        }, 'meta': {
            'description': 'Create personalised jigsaw puzzles with us here at Jigpaws. Choose an image, customise your Jigsaw using our online tool, and ship them directly to your door. Create with Jigpaws today!',
            'og_url': ' https://www.jigpaws.com/gift-products/personalised-jigsaws',
            'og_title': 'Personalised Jigsaw Puzzles | Make Your Own Jigsaw | Jigpaws',
            'og_description': 'Create personalised jigsaw puzzles with us here at Jigpaws. Choose an image, customise your Jigsaw using our online tool, and ship them directly to your door. Create with Jigpaws today!',
            'og_image': 'https://www.jigpaws.com/images/Jigpaws-social-2.jpg',
        }, 'header': {
            'title': 'Create Your Own Personalised Jigsaw Puzzle with Jigpaws',
            'description': 'Here at Jigpaws, creating your own personalised jigsaw puzzle is easy, and we will ship it right to your doorstep. Now with free UK mainland delivery available.',
            // 'description2': 'Once you have completed your fully customisable and personalised ' + 'cat gifts, you can ship them absolutely free to anywhere in the UK!'
        }, 'panel_1': {
            'title': 'Personalised Jigsaw Puzzles Made Easy',
            'description': 'If you are looking for the perfect solution for a loved one’s birthday, or if you are looking to celebrate a cherished memory, then why not create a personalised jigsaw with Jigpaws?\n\n' +
                'Our Jigsaws are entirely customisable and can include any image that you wish. Each is printed on a durable, heavy-duty card with a glossy finish.',

            'image': {
                'src': '/images/landingPages/jigsaws/generic1.webp',
                'alt': 'Jigpaws personalised gift jigsaw'
            }
        }, 'panel_2': {
            'title': 'Add An Image To Your Personalised Jigsaw Puzzle',
            'description': 'Upload your image of a loved one, family photo, wedding day image or holiday vista and see it appear on a Jigsaw, instantly.',
            'description2': 'Using our online tool you can create a personalised jigsaw puzzle in a matter of minutes and order it to your door in as little as 3-5 working days.',
            'image': {
                'src': '/images/landingPages/uploadJigsaw.jpg',
                'alt': 'Upload your image with Jigpaws',
            }
        }, 'panel_3': {
            'title': 'Personalised Jigsaw Puzzle - 500 Pieces or 1000 Pieces',
            'description': 'Each Jigpaws puzzle is made by hand. This means you can get a personalised jigsaw puzzle in various sizes. \n\n If you’re looking for a jigsaw puzzle that takes up a whole weekend, or something a little simpler, then we have the selection for you. Here at Jigpaws, we stock both 500 and 1000-piece jigsaw puzzles.',
            'list': '• 500 pieces (50x38cm)\n • 1000 pieces (66x50cm)\n',
            'image': {
                'src': '/images/landingPages/jigsaws/generic2.webp',
                'alt': 'choose your jigsaw with Jigpaws'
            }
        }, 'panel_4': {
            'title': 'Order Your Personalised Jigsaw',
            'description': 'We offer free shipping to mainland UK destinations. We estimate that your personalised jigsaw puzzle can be with you in as little as 3 to 5 working days.',
            'image': {
                'src': '/images/landingPages/jigsaws/generic3.webp',
                'alt': 'Jigpaws personalised gift jigsaw'
            }
        },  'signOffSlider': {
            'title': 'Personalised Jigsaws and Much More',
            'description': 'Here at Jigpaws, we don’t just create wonderful personalised jigsaw puzzles for you to enjoy. We also create many other fully personalised gift products, so why not take a look through our range?',
            'list': '• Cards\n • Notebooks\n • Photo Tiles\n • Cushions\n• Photo Books\n',
        },
    },
}



export default productData;