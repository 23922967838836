import React from "react"
import { Link as RouterLink } from "react-router-dom";

//MATERIALS
import Link from '@mui/material/Link';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import ShoppingBasket from '@mui/icons-material/ShoppingBasket';

//INTERNAL IMPORTS
import { useBasket } from "../../models";

export const CartLink = (props) => {
    const {user} = props;
    const [basket ] = useBasket(user);

    if (basket) {
        const itemCount = basket.totalQuantity();
        return (
            <Link component={RouterLink} to="/shop/cart" underline="hover">
                <IconButton className={"jpIcon"} color="white" size="large">
                    <Badge badgeContent={itemCount} color="secondary">
                        <ShoppingBasket color="white !important"/>
                    </Badge>
                </IconButton>
            </Link>
        );
    }
    return (null);
}