import { MenuItem, Select} from "@mui/material";
import _ from "lodash";
import React from "react";
import {makeStyles} from "@mui/styles";


function countryToFlag(isoCode) {
    return typeof String.fromCodePoint !== 'undefined' && isoCode !== 'undefined' ? isoCode.toUpperCase().replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397)) : isoCode;
}


const useStyles = makeStyles((theme) => ({
    filled: {
        borderColor: "#413E40",
        background: "#413E40",
        padding: "20px 60px 20px 60px",
    },
    dropdown: {
        color: "#413E40",
        "&:focus": {
            backgroundColor: "white"
        }
    },
    menuPaper: {
        maxHeight: "25vh",
    },
    select: {
        paddingTop: 19,
        paddingBottom: 18,
        color: "#413E40",
        "&:focus": {
            backgroundColor: "white"
        },
    },
}));


const CountrySelect2 = (props) => {
    const {countries, id, value, onChange, error, helperText, required} = props;
    const classes = useStyles();




    return (
        <Select
            className={classes.dropdown}
            size="large"
            labelId="shippingCountryLabel"
            id={id}
            value={value}
            required={required}
            label="Country"
            error={error}
            helperText={helperText}
            onChange={onChange}
            MenuProps={{classes: {paper: classes.menuPaper}}}
            variant="filled"
            // input={<Input/>}
            inputProps={{
            // id: {id},
            classes: { select: classes.select }
          }}
        >
            {_.map(countries, (option) => {
                return (
                    <MenuItem value={option.isoCode}>
                    <span>{countryToFlag(option.isoCode)}</span>
                        {option.name}
                    </MenuItem>
                );
            })}
        </Select>
    )
}

export default CountrySelect2