import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/system';  // Corrected import for styled
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import { makeStyles } from '@mui/styles';
import { DialogContent } from '@mui/material';
import Typography from '@mui/material/Typography';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        paddingTop: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        textAlign: "center",
    },
  '& .MuiDialogTitle-root': {
        padding: 0,
  },
  '& .MuiDialogActions-root': {
        justifyContent: "center",
  },
    '& .MuiDialogActions-spacing > :not(:first-child)': {
        marginLeft: theme.spacing(2),
    }
}));


const useStyles = makeStyles((theme) => ({
    title: {
        paddingBottom: theme.spacing(2),
    },
    button: {
        width: "150px",
    }
}));


export default function ConfirmationDialog({title, message, open, handleCancel, handleConfirm, cancelText, confirmText}) {
    const classes = useStyles();

    return (
        <BootstrapDialog

            onClose={handleCancel}
            open={open}
            maxWidth={"lg"}
            PaperProps={{
                style: {borderRadius: 0, backgroundColor: "white"}

            }}
        >

            <DialogTitle style={{margin: "auto"}}>
                <h2 className={classes.title}>{title}</h2>
            </DialogTitle>
            {message &&
                <DialogContent style={{color: "#85A2AD"}}>
                    <Typography gutterBottom>
                        {message}
                    </Typography>
                </DialogContent>
            }

            <DialogActions>
                <Button style={{fontSize: "20px"}} className={classes.button} variant="contained" size="large" autoFocus
                        onClick={handleCancel}>{cancelText}</Button>
                <Button style={{fontSize: "20px"}} className={classes.button} variant="contained" size="large" onClick={handleConfirm}>
                    {confirmText}
                </Button>
            </DialogActions>
        </BootstrapDialog>
    );
}