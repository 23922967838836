import React from "react"

//MATERIALS
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';


//INTERNAL IMPORTS
import { useLocale } from '../../api-locale';
import { createUrl, jigpawAPI, useCurrencies } from "../../API"

//EXTERNAL IMPORTS
import { useSWRConfig } from "swr";
import _ from "lodash";
import CurrencyFlag from 'react-currency-flags';

export const CurrencyMenu = (props) => {
    const {user, anchorEl, isMenuOpen, handleMenuClose} = props;
    const [currencyResponse ] = useCurrencies(user);
    const currencies = _.get(currencyResponse, "currencies");
    const {locale } = useLocale(user);
    const {  mutate } = useSWRConfig()


    if (currencies) {

        const updateCurrency = async (currency) => {
            handleMenuClose();
            const newLocale = { ...locale, currencyCode: currency }
            const options = {  revalidate: false, populateCache: true, optimisticData: newLocale, rollbackOnError: true}
            await mutate([createUrl("api/locale"), user], jigpawAPI.update_currency(user, currency), options)
        }

        return (
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                id="currency-menu"
                keepMounted
                transformOrigin={{vertical: 'top', horizontal: 'right'}}
                open={isMenuOpen}
                onClose={handleMenuClose}
                 PaperProps={{
                            style: {
                                maxHeight: 200,
                                // width: 200,
                            },
                        }}
                    >

                {currencies.map((currency, i) => <MenuItem onClick={() => updateCurrency(currency)}  key={i}><CurrencyFlag currency={currency} size="sm" /><span style={{whiteSpace: "pre"}}>  {currency}</span></MenuItem>)}
            </Menu>
        );
    }
    return (null);
}