import { makeStyles } from '@mui/styles';

const useStyles_LandingPage = makeStyles((theme) => ({
  root: {
    height: "10px",
    backgroundColor: "#413e40",
  },
  moveToFront: {
    [theme.breakpoints.down('md')]: {
      order: -1,
    },
  },
}));

export { useStyles_LandingPage };