import React, { useState } from "react";
import { CircularProgress } from "@mui/material";

const RetryImage = (props) => {
    const { maxRetries = 25, delay = 500, src } = props;
    const [loading, setLoading] = useState(false);
    const [retryDelay, setRetryDelay] = useState(delay);
    const [retryCount, setRetryCount] = useState(0);

    // Error handling and retry logic
    const onError = () => {
        console.log("Error loading image. Retrying...", { retryCount, retryDelay });
        if (retryCount < maxRetries) {
            setLoading(true);
            setRetryDelay(retryDelay * 2);
            setRetryCount(retryCount + 1);
            setTimeout(() => {
                setLoading(false);
            }, retryDelay);
        }
    };

    if (!src || loading) {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <CircularProgress/>
            </div>
        );
    }

    return (
        <img
            src={src}
            alt={props.alt || "Image"}
            width="100%"
            height="100%"
            onError={onError}
            style={{ display: 'block' }}
        />
    );
}

export default RetryImage;
