import React from "react";
import {Box, LinearProgress, Backdrop, CircularProgress, Grid} from "@mui/material";
import {makeStyles} from "@mui/styles";
import Delayed from "./Delayed";


const useStyles = makeStyles((theme) => ({
    container: {
        height: "100%",
    },
    fullscreen: {
        height: "52.8vh",
        [theme.breakpoints.down('md')]: {
            height: "33"
        },
    }
}));

export const DelayedLinearProgress = () => {
    return (
        <Delayed waitBeforeShow={1000}>
            <Box sx={{width: '100%'}}>
                <LinearProgress />
            </Box>
        </Delayed>
    );
}

export const BackdropProgress = () => {
    return (
        <div style={{"height": "55vh"}}>
        <Backdrop open={true} style={{"z-index": "10000"}}>
              <CircularProgress color="inherit" />
        </Backdrop>
        </div>
    )
}

export const PageProgress = (props) => {
    const classes = useStyles();
    return (
        <Grid container className={classes.fullscreen} justifyContent={"center"} alignItems={"center"}>
            <CircularProgress {...props}/>
        </Grid>
    );
}

export const DelayedCircularProgress = (props) => {
    return (
        <Delayed waitBeforeShow={500}>
            <JPCircularProgress {...props}/>
        </Delayed>
    );
}

export const JPCircularProgress = (props) => {
    const classes = useStyles();
    return (
        <Grid container className={classes.container} justifyContent={"center"} alignItems={"center"}>
            <CircularProgress {...props}/>
        </Grid>
    );
}





