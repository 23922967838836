import useSWR from "swr";
import axios from "axios";
import {createUrl} from "./API";


const fetchWithToken = async (url, user) => {
  const token = await user.getIdToken();
  return axios.get(url, {
    headers: {
      'Authorization': 'Bearer ' + token
    },
  }).then(res => res.data);
}

export const useLocale = (user) => {
  const { data, mutate, error } = useSWR([createUrl('api/locale'), user], fetchWithToken);
  // const { data, error } = useSWR(['/api/locale', user], fetchWithToken);

  return {
    locale: data,
    isLoading: !error && !data,
    error: error,
    update: mutate
  };
};