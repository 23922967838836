
import { makeStyles } from '@mui/styles';

const rowHeight = 150;

const useStyles_footer = makeStyles((theme) => ({
    root: {
      textAlign: "center",
    },
    item: {
      display: "inline-block",
      color: '#413e40',
      fontSize: 12,
    },
    social: {
      backgroundColor: "#413e40",
      minHeight: rowHeight,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      [theme.breakpoints.down('lg')]: {
        height: "25vh",
        minHeight: 0,
      },
    },
    socialIcon: {
      width: 75,
      height: 75,
      marginLeft: 10,
      marginRight: 10,
      marginTop: 10,
      [theme.breakpoints.down('lg')]: {
        width: "10vw",
        height: "10vw",
      }
    },
    footerLinebreak: {
      backgroundColor: "#85a2ad",
      height: "10px",
    },
    legal: {
      height: 340,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      backgroundColor: '#eaeaea',
      color: '#413e40',
    },
    legalLinks: {
      marginTop: 10,
      [theme.breakpoints.down('lg')]: {
        marginTop: 20,
      },
      marginBottom: 20,
      [theme.breakpoints.down('lg')]: {
        marginBottom: 10,
      },
    },
    legalLink: {
      "&:hover": {
        color: "#99bfc2"
      }
    },
    brand: {
      marginTop: 10,
      [theme.breakpoints.down('lg')]: {
        marginTop: 20,
      },
    }
  }));

  export default useStyles_footer